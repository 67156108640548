@import "../../../assets/styles/variables";

.global-snackbar {
	.MuiSnackbar-anchorOriginBottomRight {
		right: 68px;
		bottom: 31px;
		max-width: 400px;
	}
	.MuiSnackbar-root svg {
		color: unset;
	}
	.bimify-progress,
	.download-zip-files,
	.upload-files {
		min-width: 400px;
		.btn--close {
			width: 20px;
			height: 20px;

			&--white {
				right: 15px;
				top: 15px;
				padding: 6px;
			}
		}

		.MuiAccordionSummary-root {
			background-color: $n500;
			color: $n000;
			border-radius: $borderRadiusSmall;
		}

		.MuiAccordionSummary-content {
			margin: $regular 0;
		}
		.MuiAccordionSummary-content.Mui-expanded {
			margin: $regular 0;
		}
		.MuiAccordionSummary-root.Mui-expanded {
			min-height: unset;
		}

		.MuiAccordionSummary-expandIconWrapper {
			margin-right: 25px;
			svg {
				color: $n000;
			}
		}
		.MuiAccordionDetails-root {
			padding: $s 0px;
			overflow-y: auto;
			max-height: 400px;
		}
	}

	.download-zip-files {
		.MuiAccordionSummary-expandIconWrapper {
			margin-right: 0px;
			svg {
				color: $n000;
			}
		}
	}
}
