/**Import custom fonts */

// @font-face {
// 	font-family: MontHeavy;
// 	src: url(../fonts/Mont-Heavy.ttf), url(../fonts/Mont-Heavy.otf);
// 	font-weight: bolder;
// 	font-style: normal;
// }

// @font-face {
// 	font-family: MontBold;
// 	src: url(../fonts/Mont-Bold.ttf), url(../fonts/Mont-Bold.otf);
// 	font-weight: bold;
// 	font-style: normal;
// }

@font-face {
	font-family: OpenSansRegular;
	src:
		local("OpenSansRegular"),
		url("../fonts/OpenSans-Regular.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

// @font-face {
// 	font-family: OpenSansLight;
// 	src: url(../fonts/OpenSans-Light.ttf);
// 	font-weight: lighter;
// 	font-style: normal;
// }

@font-face {
	font-family: OpenSansBold;
	src:
		local("OpenSansBold"),
		url(../fonts/OpenSans-Bold.ttf) format("truetype");
	font-weight: bold;
	font-style: normal;
}

// @font-face {
// 	font-family: MerriweatherBold;
// 	src: url(../fonts/Merriweather-Bold.ttf);
// 	font-weight: bold;
// 	font-style: normal;
// }

@font-face {
	font-family: InterRegular;
	src:
		local("InterRegular"),
		url("../fonts/Inter-VariableFont.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: InterMedium;
	src:
		local("InterMedium"),
		url("../fonts/Inter-VariableFont.ttf") format("truetype");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: InterSemibold;
	src:
		local("InterSemibold"),
		url("../fonts/Inter-VariableFont.ttf") format("truetype");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: InterBold;
	src:
		local("InterBold"),
		url("../fonts/Inter-VariableFont.ttf") format("truetype");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: InterItalic;
	src:
		local("InterItalic"),
		url("../fonts/Inter-VariableFont.ttf") format("truetype");
	font-weight: 400;
	font-style: italic;
}
