@import "../variables";

body {
	font-family: $interRegular;
	color: $n500;
	font-size: 0.875rem;
	line-height: 1.5;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	margin-top: 0;
	margin-bottom: 0;
	color: $n500;
}

h1,
.h1 {
	font-size: 2.5rem;
	line-height: 1.4;
	font-family: $interBold;
	&.small {
		font-size: 1.75rem;
	}
}

h2,
.h2 {
	font-size: 2rem;
	line-height: 1.25;
	font-family: $interBold;
	&.small {
		font-size: 1.5rem;
	}
}

h3,
.h3 {
	font-size: 1.5rem;
	line-height: 1.33;
	font-family: $interBold;

	&.small {
		font-size: 1.25rem;
		line-height: 1.75;
	}
	&.semi {
		line-height: 1.5;
		font-family: $interSemibold;
	}
}

h4,
.h4 {
	font-size: 1rem;
	line-height: 1.5;

	font-family: $interSemibold;

	&.small {
		font-size: 0.875rem;
		line-height: 1.4;
	}
}

h5,
.h5 {
	font-size: 0.875rem;
	line-height: 1.5;
	font-family: $interBold;
	&.uppercase {
		text-transform: uppercase;
	}
	&.small {
		font-size: 0.75rem;
	}
}

h6,
.h6 {
	font-size: 0.6875rem;
	line-height: 1.45;
	letter-spacing: 0.6px;
	font-family: $interBold;
	color: $n400;
	text-transform: uppercase;
	&.small {
		line-height: 1.27;
	}
}

.title-divider,
.divider {
	width: 100%;
	white-space: nowrap;
	display: flex;
	align-items: flex-end;
	&:after {
		content: "";
		display: block;
		width: 100%;
		height: 1px;
		background-color: $n150;
		margin-left: 12px;
		margin-bottom: 5px;
	}
	&--bottom {
		align-items: flex-start;
		flex-direction: column;
		&:after {
			margin: $s 0 0 0;
		}
	}
}

.divider::after {
	margin-left: 0px;
}

.body-font {
	font-size: 1rem;
	line-height: 1.5rem;
	&.regular {
		font-family: $interRegular;
	}
	&.semi {
		font-family: $interSemibold;
	}
	&--small {
		font-size: 0.875rem;
		&.semi {
			font-family: $interSemibold;
		}
	}
	&--xs {
		font-size: 0.75rem;
		line-height: 1.25;
		&.semi {
			font-family: $interSemibold;
		}
	}
	&--xxs {
		font-size: 0.688rem;
		line-height: 1.25;
	}
}

.btn--link {
	@extend .body-font--xs;
	text-decoration: underline;
	font-family: $interRegular;
}

strong,
.strong {
	font-family: $interBold;
	&.semi {
		font-family: $interSemibold;
	}
}

sup {
	vertical-align: baseline;
	position: relative;
	top: -0.4em;
}

p {
	&:first-of-type {
		margin-top: 0;
	}
	&:last-child {
		margin-bottom: 0;
	}
}

a {
	color: $primary;
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
	&.no-decoration {
		color: inherit;
		&:hover {
			text-decoration: none;
			color: $primary;
		}
	}

	&.lh-1 {
		line-height: 1;
	}
}

.capitalize {
	text-transform: capitalize;
}

code {
	color: $red;
	background-color: $n150;
	padding: 2px;
	display: inline-flex;
	&.snippet {
		display: block;
		background-color: $n500;
		color: $n000;
		border-radius: $borderRadiusSmall;
		padding: $regular;
		position: relative;
		&--var {
			background-color: $n150;
			color: $n500;
		}
		pre {
			margin: 0;
			tab-size: 24px;
		}
	}
}
