@import "../../assets/styles/_variables";
//CSS file related to the sidebar only
.sidebar {
	width: 80px;
	height: 100vh;
	z-index: 6;
	background-color: $n500;
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.07);

	a {
		display: flex;
		justify-content: center;
		align-items: center;
		transition: 0.4s ease;
	}

	&__home-link {
		padding-top: 20px;
		//	margin-bottom: 152px;
		position: relative;

		img {
			height: 35px;
		}

		&:hover {
			.hover-info {
				display: block;
			}
		}
	}

	&__link-wrapper {
		margin-bottom: 16px;
		display: flex;
		justify-content: center;
		width: 100%;
		position: relative;

		&:hover {
			a {
				background-color: $greyLight;
			}
			.hover-info {
				display: block;
			}
		}
	}

	&__navigation {
		align-items: center;

		a {
			// height: 48px;
			// width: 48px;
			height: 40px;
			width: 40px;
			border-radius: 5px;
			position: relative;
			svg * {
				fill: $n000;
			}

			&.coming-soon {
				pointer-events: none;
				&::before {
					content: "";
					content: "";
					position: absolute;
					right: 6px;
					top: 5px;
					height: 17px;
					width: 17px;
					background-color: $gainsboroLight;
					background-image: url(../../assets/images/nav-icons/coming-soon-icon.svg);
					background-size: auto 10px;
					background-repeat: no-repeat;
					background-position: center;
					transition: 0.4s ease;
					border-radius: 50%;
				}
			}

			&::after {
				content: "";
				position: absolute;
				right: -15px;
				height: 60px;
				width: 2px;
				transition: 0.4s ease;
				background-color: transparent;
			}

			.icon {
				display: flex;
			}

			&.active {
				background-color: $primary;
				height: 48px;
				width: 48px;
				transition: 0.4s ease;
			}
		}

		img {
			height: auto;
			width: auto;
			max-width: 22px;
			max-height: 22px;
		}
	}
}
