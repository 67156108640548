@import "../../assets/styles/variables";

.btn {
	&--save {
		background-color: $primary;
	}
}

.form {
	&__input--password.MuiFormControl-root {
		margin-top: 42px;
		margin-bottom: 0;
	}

	.profile-image {
		height: 100px;
		width: 100px;
		border-radius: 50%;
		min-height: unset;
		margin: 0 auto;
		position: relative;
		background-size: cover;
		background-position: center;
		box-shadow: 0px 0px 5px $greySuperLight;
		&--input {
			border-style: dashed;
		}
	}
}
