.single-order {
	&__terms {
		padding-bottom: 48px;
		.MuiAccordionSummary-root {
			padding-right: 24px;
		}
		.z-index--2 {
			z-index: 2;
		}
	}
	&__specification {
		.MuiAccordionSummary-root {
			padding-left: 0px;
			padding-right: 0px;
		}
		.MuiAccordionDetails-root {
			padding: 0px;
		}
	}
}
