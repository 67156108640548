@import "./variables";

@-webkit-keyframes pulse {
	0% {
		-webkit-box-shadow: 0 0 0 0 $primary;
	}
	70% {
		-webkit-box-shadow: 0 0 0 2px $primaryDark;
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 $primaryDark;
	}
}
@keyframes pulse {
	0% {
		-moz-box-shadow: 0 0 0 0 $primary;
		box-shadow: 0 0 0 0 $primary;
	}
	70% {
		-moz-box-shadow: 0 0 0 2px $primaryDark;
		box-shadow: 0 0 0 2px $primaryDark;
	}
	100% {
		-moz-box-shadow: 0 0 0 0 $primaryDark;
		box-shadow: 0 0 0 0 $primaryDark;
	}
}

@keyframes notificationPulse {
	0% {
		box-shadow: 0 0 0px 3px $n000, 0 0 0 3px $n000, 0 0 0 6px $n000;
	}
	33% {
		box-shadow: 0 0 0px 3px $n000, 0 0 0 3px $primaryLighter,
			0 0 0 6px $primaryLighter;
	}
	66% {
		box-shadow: 0 0 0px 3px $n000, 0 0 0 3px $primaryLight,
			0 0 0 6px $primaryLight;
	}
	100% {
		box-shadow: 0 0 0px 3px $n000, 0 0 0 3px $primaryLighter,
			0 0 0 6px $primaryLighter;
	}
}

@keyframes notificationPulseInfobar {
	0% {
		box-shadow: 0 0 0px 3px $n150, 0 0 0 3px $n150, 0 0 0 6px $n150;
	}
	33% {
		box-shadow: 0 0 0px 3px $n150, 0 0 0 3px $primaryLighter,
			0 0 0 6px $primaryLighter;
	}
	66% {
		box-shadow: 0 0 0px 3px $n150, 0 0 0 3px $primaryLight,
			0 0 0 6px $primaryLight;
	}
	100% {
		box-shadow: 0 0 0px 3px $n150, 0 0 0 3px $primaryLighter,
			0 0 0 6px $primaryLighter;
	}
}

@keyframes pulse-ring {
	0% {
		transform: scale(0.33);
		opacity: 1;
	}
	100% {
		transform: scale(1);
		opacity: 0;
	}
}

@keyframes pulse-dot {
	0% {
		transform: scale(0.8);
	}
	50% {
		transform: scale(1);
	}
	100% {
		transform: scale(0.8);
	}
}
