@import "../../assets/styles/variables";

.topbar {
	top: 0px;
	position: fixed;
	width: 100%;
	right: 0;
	height: $topbarHeight;
	padding: 0 40px;
	background: $n150;
	background: #fff;
	border-bottom: 1px solid $greyLight;
	justify-content: flex-end;
	align-items: center;
	display: flex;
	z-index: 5;

	&--helper {
		height: 60px;
		width: 100%;
		display: block;
	}

	.breadcrumb-items {
		position: relative;
		margin: auto;
		margin-right: auto;
		margin-left: 80px;
		font-size: 1rem;
		line-height: 1.5;
		font-family: $interMedium;

		.subcription__plan {
			background: $primary;
			color: $n000;
			padding: 2px $s;
			border-radius: $borderRadiusLarge;
			margin-left: $s;
			&--icon {
				margin-right: 6px;
				svg * {
					fill: $n000;
				}
				svg {
					width: 15px;
					height: 15px;
				}
			}
			.text {
				color: $n000;
				letter-spacing: 0.6px;
			}
		}

		.new-icon {
			svg * {
				fill: $primary;
			}
		}

		.link {
			color: $n300;
			margin: 0 $s;
			cursor: pointer;
			display: flex;
		}

		.list-item {
			&:last-child {
				font-family: $interBold;
				.link {
					color: $n500;
					pointer-events: none;
					text-decoration: none;
					cursor: initial;
				}
			}
		}
	}
	.w-global-company {
		width: calc(100% - 200px);
	}

	.user-notifications,
	.user-profile {
		position: relative;
		margin-right: 8px;
		&__button {
			background: none;
			border: none;
			display: flex;
			outline: none;
			align-items: center;
		}
	}

	.user-profile {
		position: relative;
		&__button {
			margin-left: $s;

			padding: 5px 0 5px 0px;
		}

		&__info {
			&:hover,
			&--active {
				color: $greySuperDark;
			}
		}

		&__dropdown-toggle {
			background: none;
			border: none;

			transition: 0.4s ease-in-out;
			text-align: left;
			outline: none;
			display: flex;
			justify-content: center;
			&.active {
				transform: rotateX(180deg);
			}
		}

		&__dropdown {
			display: none;
			position: absolute;
			width: 210px;
			background: white;
			box-shadow: 0 0 5px #0000000d;
			border-radius: $borderRadiusSmall;
			top: calc(100% + 5px);
			right: 0;
			&:before {
				content: "";
				background-image: url(../../assets/images/profile-icons/dropdown-icon.svg);
				display: block;
				width: 46px;
				height: 22px;
				position: absolute;
				top: -8px;
				right: 37px;
			}
			&--visible {
				display: flex;
			}
		}
	}
}
