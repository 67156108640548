@import "../assets/styles/_variables";
//CSS file related only to app component
.main {
	width: 100%;
	padding-left: $sidebarWidth;
	min-height: 100vh;
	.outlet {
		padding: 36px;
		padding-top: 24px;
		min-height: calc(100vh - 60px);
	}
	.negative-outlet-padding {
		margin: -36px;
		margin-top: -24px;
	}

	.mh-outlet {
		min-height: calc(100vh - 120px);
	}
}
