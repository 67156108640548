@import "./variables";

::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: $n300 !important;
	opacity: 1 !important;
	transition: all 0.25s;
}
::-moz-placeholder {
	/* Firefox 19+ */
	color: $n300 !important;
	opacity: 1 !important;
	transition: all 0.25s;
}
:-ms-input-placeholder {
	/* IE 10+ */
	color: $n300 !important;
	opacity: 1 !important;
	transition: all 0.25s;
}
:-moz-placeholder {
	/* Firefox 18- */
	color: $n300 !important;
	opacity: 1 !important;
	transition: all 0.25s;
}

//HOVER

.field__root:hover::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: $n300 !important;
}
.field__root:hover::-moz-placeholder {
	/* Firefox 19+ */
	color: $n300 !important;
}
.field__root:hover:-ms-input-placeholder {
	/* IE 10+ */
	color: $n300 !important;
}
.field__root:hover:-moz-placeholder {
	/* Firefox 18- */
	color: $n300 !important;
}

//ERROR

.field__root--error::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: $n500 !important;
}
.field__root--error::-moz-placeholder {
	/* Firefox 19+ */
	color: $n500 !important;
}
.field__root--error:-ms-input-placeholder {
	/* IE 10+ */
	color: $n500 !important;
}
.field__root--error:-moz-placeholder {
	/* Firefox 18- */
	color: $n500 !important;
}

//FOCUS

.field__root:focus::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: $n500 !important;
}
.field__root:focus::-moz-placeholder {
	/* Firefox 19+ */
	color: $n500 !important;
}
.field__root:focus:-ms-input-placeholder {
	/* IE 10+ */
	color: $n500 !important;
}
.field__root:focus:-moz-placeholder {
	/* Firefox 18- */
	color: $n500 !important;
}

//COMPLETED

.field__root--completed::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: $n500 !important;
}
.field__root--completed::-moz-placeholder {
	/* Firefox 19+ */
	color: $n500 !important;
}
.field__root--completed:-ms-input-placeholder {
	/* IE 10+ */
	color: $n500 !important;
}
.field__root--completed:-moz-placeholder {
	/* Firefox 18- */
	color: $n500 !important;
}

.field__root--editor::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: $n300 !important;
}
.field__root--editor::-moz-placeholder {
	/* Firefox 19+ */
	color: $n300 !important;
}
.field__root--editor:-ms-input-placeholder {
	/* IE 10+ */
	color: $n300 !important;
}
.field__root--editor:-moz-placeholder {
	/* Firefox 18- */
	color: $n300 !important;
}
