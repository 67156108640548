@import "../variables";

$sizes: (
	"0": 0,
	"5": 5px,
	"6": 6px,
	"7": 7px,
	"regular": $regular,
	"m": $m,
	"s": $s,
	"xs": $xs,
	"xxs": $xxs,
	"10": 10px,
	"14": 14px,
	"15": 15px,
	"16": $regular,
	"17": 17px,
	"20": 20px,
	"24": 24px,
	"25": 25px,
	"26": 26px,
	"28": 28px,
	"30": 30px,
	"32": 32px,
	"34": 34px,
	"35": 35px,
	"36": 36px,
	"40": 40px,
	"42": 42px,
	"44": 44px,
	"48": 48px,
	"50": 50px,
	"52": 52px,
	"54": 54px,
	"56": 56px,
	"60": 60px,
	"63": 63px,
	"64": 64px,
	"66": 66px,
	"68": 68px,
	"72": 72px,
	"75": 75px,
	"80": 80px,
	"82": 82px,
	"90": 90px,
	"100": 100px,
	"104": 104px,
	"120": 120px,
	"124": 124px,
	"130": 130px,
	"140": 140px,
	"150": 150px,
	"160": 160px,
	"166": 166px,
	"188": 188px,
	"278": 278px,
	"25p": 25%,
);

$keys: (
	"p": "padding",
	"m": "margin",
);

@each $name, $size in $sizes {
	.top-#{$name} {
		top: $size;
	}
	.bottom-#{$name} {
		bottom: $size;
	}

	.left-#{$name} {
		left: $size;
	}
	.right-#{$name} {
		right: $size;
	}
	@each $key, $property in $keys {
		.#{$key}b-#{$name},
		.#{$key}y-#{$name},
		.#{$key}-#{$name} {
			#{$property}-bottom: $size;
		}

		.#{$key}t-#{$name},
		.#{$key}y-#{$name},
		.#{$key}-#{$name} {
			#{$property}-top: $size;
		}

		.#{$key}r-#{$name},
		.#{$key}x-#{$name},
		.#{$key}-#{$name} {
			#{$property}-right: $size;
		}

		.#{$key}l-#{$name},
		.#{$key}x-#{$name},
		.#{$key}-#{$name} {
			#{$property}-left: $size;
		}

		.#{$key}b-neg-#{$name},
		.#{$key}y-neg-#{$name},
		.#{$key}-neg-#{$name} {
			#{$property}-bottom: -$size;
		}

		.#{$key}t-neg-#{$name},
		.#{$key}y-neg-#{$name},
		.#{$key}-neg-#{$name} {
			#{$property}-top: -$size;
		}

		.#{$key}r-neg-#{$name},
		.#{$key}x-neg-#{$name},
		.#{$key}-neg-#{$name} {
			#{$property}-right: -$size;
		}

		.#{$key}l-neg-#{$name},
		.#{$key}x-neg-#{$name},
		.#{$key}-neg-#{$name} {
			#{$property}-left: -$size;
		}
	}
}

@each $name, $size in $sizes {
	@each $key, $property in $keys {
		@include mq("tablet") {
			.#{$key}b-tablet-#{$name},
			.#{$key}y-tablet-#{$name},
			.#{$key}-tablet-#{$name} {
				#{$property}-bottom: $size;
			}

			.#{$key}t-tablet-#{$name},
			.#{$key}y-tablet-#{$name},
			.#{$key}-tablet-#{$name} {
				#{$property}-top: $size;
			}

			.#{$key}r-tablet-#{$name},
			.#{$key}x-tablet-#{$name},
			.#{$key}-tablet-#{$name} {
				#{$property}-right: $size;
			}

			.#{$key}l-tablet-#{$name},
			.#{$key}x-tablet-#{$name},
			.#{$key}-tablet-#{$name} {
				#{$property}-left: $size;
			}

			.#{$key}b-tablet-neg-#{$name},
			.#{$key}y-tablet-neg-#{$name},
			.#{$key}-tablet-neg-#{$name} {
				#{$property}-bottom: -$size;
			}

			.#{$key}t-tablet-neg-#{$name},
			.#{$key}y-tablet-neg-#{$name},
			.#{$key}-tablet-neg-#{$name} {
				#{$property}-top: -$size;
			}

			.#{$key}r-tablet-neg-#{$name},
			.#{$key}x-tablet-neg-#{$name},
			.#{$key}-tablet-neg-#{$name} {
				#{$property}-right: -$size;
			}

			.#{$key}l-tablet-neg-#{$name},
			.#{$key}x-tablet-neg-#{$name},
			.#{$key}-tablet-neg-#{$name} {
				#{$property}-left: -$size;
			}
		}
	}
}

@each $name, $size in $sizes {
	@each $key, $property in $keys {
		@include mq("desktop") {
			.#{$key}b-desktop-#{$name},
			.#{$key}y-desktop-#{$name},
			.#{$key}-desktop-#{$name} {
				#{$property}-bottom: $size;
			}

			.#{$key}t-desktop-#{$name},
			.#{$key}y-desktop-#{$name},
			.#{$key}-desktop-#{$name} {
				#{$property}-top: $size;
			}

			.#{$key}r-desktop-#{$name},
			.#{$key}x-desktop-#{$name},
			.#{$key}-desktop-#{$name} {
				#{$property}-right: $size;
			}

			.#{$key}l-desktop-#{$name},
			.#{$key}x-desktop-#{$name},
			.#{$key}-desktop-#{$name} {
				#{$property}-left: $size;
			}

			.#{$key}b-desktop-neg-#{$name},
			.#{$key}y-desktop-neg-#{$name},
			.#{$key}-desktop-neg-#{$name} {
				#{$property}-bottom: -$size;
			}

			.#{$key}t-desktop-neg-#{$name},
			.#{$key}y-desktop-neg-#{$name},
			.#{$key}-desktop-neg-#{$name} {
				#{$property}-top: -$size;
			}

			.#{$key}r-desktop-neg-#{$name},
			.#{$key}x-desktop-neg-#{$name},
			.#{$key}-desktop-neg-#{$name} {
				#{$property}-right: -$size;
			}

			.#{$key}l-desktop-neg-#{$name},
			.#{$key}x-desktop-neg-#{$name},
			.#{$key}-desktop-neg-#{$name} {
				#{$property}-left: -$size;
			}
		}
	}
}

$stringKeys: (
	"auto": auto,
	"unset": unset,
);

@each $key, $value in $stringKeys {
	.mb-#{$key},
	.my-#{$key},
	.m-#{$key} {
		margin-bottom: $value;
	}

	.mt-#{$key},
	.my-#{$key},
	.m-#{$key} {
		margin-top: $value;
	}

	.mr-#{$key},
	.mx-#{$key},
	.m-#{$key} {
		margin-right: $value;
	}

	.ml-#{$key},
	.mx-#{$key},
	.m-#{$key} {
		margin-left: $value;
	}
}

@each $name, $size in $sizes {
	@each $key, $property in $keys {
		.MuiFormControl-root.#{$key}b-#{$name},
		.MuiFormControl-root.#{$key}y-#{$name},
		.MuiFormControl-root.#{$key}-#{$name} {
			#{$property}-bottom: $size;
		}

		.MuiFormControl-root.#{$key}t-#{$name},
		.MuiFormControl-root.#{$key}y-#{$name},
		.MuiFormControl-root.#{$key}-#{$name} {
			#{$property}-top: $size;
		}

		.MuiFormControl-root.#{$key}r-#{$name},
		.MuiFormControl-root.#{$key}x-#{$name},
		.MuiFormControl-root.#{$key}-#{$name} {
			#{$property}-right: $size;
		}

		.MuiFormControl-root.#{$key}l-#{$name},
		.MuiFormControl-root.#{$key}x-#{$name},
		.MuiFormControl-root.#{$key}-#{$name} {
			#{$property}-left: $size;
		}

		.MuiFormControl-root.#{$key}b-neg-#{$name},
		.MuiFormControl-root.#{$key}y-neg-#{$name},
		.MuiFormControl-root.#{$key}-neg-#{$name} {
			#{$property}-bottom: -$size;
		}

		.MuiFormControl-root.#{$key}t-neg-#{$name},
		.MuiFormControl-root.#{$key}y-neg-#{$name},
		.MuiFormControl-root.#{$key}-neg-#{$name} {
			#{$property}-top: -$size;
		}

		.MuiFormControl-root.#{$key}r-neg-#{$name},
		.MuiFormControl-root.#{$key}x-neg-#{$name},
		.MuiFormControl-root.#{$key}-neg-#{$name} {
			#{$property}-right: -$size;
		}

		.MuiFormControl-root.#{$key}l-neg-#{$name},
		.MuiFormControl-root.#{$key}x-neg-#{$name},
		.MuiFormControl-root.#{$key}-neg-#{$name} {
			#{$property}-left: -$size;
		}
	}
}
