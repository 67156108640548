.form:not(.standardized-form) {
	flex-wrap: wrap;

	.form__input--password,
	.form__input--password-margin,
	.form__input--select {
		.form__error {
			top: 100%;
		}
		&.w-52 {
			width: 52%;
		}
		&.f-right {
			margin-left: 2%;
		}
	}

	.form__input--select,
	.text-field-wrapper {
		position: relative;
		&.w-50 {
			width: 48%;
		}
		&.f-left {
			margin-right: 2%;
		}
		&.f-right {
			margin-left: 2%;
		}
		&.f-right-calendar-completed {
			margin-left: 18%;
		}
		&.f-right-calendar {
			margin-left: 8%;
		}
		&.w-40 {
			width: 40%;
		}

		&.w-33 {
			width: 31%;
		}
		&.w-66 {
			width: 64%;
		}
		&.center {
			margin-left: 2%;
			margin-right: 2%;
		}
	}

	.form__input--password-margin {
		margin-top: 2.7rem;
	}
	.form__input--normal-margin {
		margin-top: 1.5rem;
	}
	.form__input {
	}
	label + .MuiInput-formControl {
		margin-top: 0.8rem;
	}
	.MuiSelect-select:focus {
		background: white;
	}
	.MuiInputBase-root,
	.MuiFormControl-root,
	.MuiAutocomplete-root {
		justify-content: center;
		position: relative;
	}
	.MuiInputBase-input,
	.MuiInputLabel-root {
		font-family: $openSansRegular;
		font-size: 0.875rem;
		top: -0.5rem;
	}
	.MuiInput-underline:hover:not(.Mui-disabled):before {
		border-bottom: 1px solid rgba(0, 0, 0, 0.8);
	}

	.MuiInputBase-input,
	.MuiAutocomplete-inputRoot[class*="MuiInput-root"]
		.MuiAutocomplete-input:first-child {
		color: black;
		padding-top: 0;
		height: 1.5rem;
		padding-left: 1rem;
		padding-bottom: 0.1rem;
	}
	.MuiAutocomplete-inputRoot[class*="MuiInput-root"]
		.MuiAutocomplete-input:first-child {
		padding-left: 2.4rem;
	}
	.MuiAutocomplete-root {
		display: flex;
		&:before {
			content: "";
			position: absolute;
			width: 12px;
			height: 15px;
			display: block;
			top: 40%;
			left: 1rem;
			background: url(../images/address-icon.svg) center no-repeat;
			background-size: contain;
		}
		.form__error {
			top: 100%;
			left: 0;
		}
	}

	.form_error-icon {
		svg {
			path {
				color: $red;
			}
		}
	}
	.MuiIconButton-root,
	.MuiInputAdornment-positionStart {
		padding: 0;
		margin-bottom: 0.3rem;
		margin-right: 1rem;
		&:hover {
			background: none;
		}
	}
	.MuiInput-underline {
		&:before {
			border-bottom-width: 1px;
		}
		&:after {
			border-bottom-width: 2px;
			border-bottom-color: $primary;
		}
		&.Mui-error {
			&:after {
				border-bottom-width: 1px;
				border-bottom-color: transparent;
			}
		}
	}

	.MuiAutocomplete-root {
		.MuiInputLabel-root {
			padding-left: 2.325rem;
		}
	}

	.MuiInputLabel-root {
		padding-left: 1.125rem;
		color: $greyDark;
		&.Mui-focused {
			color: $primary;
		}
		&.MuiFormLabel-filled {
			color: $grey;
		}
		&.Mui-error {
			color: $red;
		}
	}
	.MuiInputLabel-shrink {
		transform: translate(0);
		font-size: 0.75rem;
		top: 0;
	}
	.MuiFormHelperText-root {
		color: $red;
	}

	.form__error {
		position: absolute;
		text-align: left;
		font-size: 0.75rem;
		color: $red;
		line-height: 14px;
		display: block;
		padding-top: 0.1rem;
		padding-left: 1rem;
	}
	.reset-password {
		margin-top: 2.625rem;
		.reset-password__btn {
			color: $primary;
			background: none;
			font-family: $openSansRegular;
			border: none;
			text-decoration: none;
			font-size: 0.8rem;
			cursor: pointer;
			&:hover {
				color: black;
			}
		}
	}
	.form__global-error {
		background: $red;
		font-size: 0.85rem;
		display: block;
		margin-top: 1rem;
		color: $n000;
		padding: 0.6rem 1rem;
	}

	.MuiAutocomplete-endAdornment {
		display: none;
	}

	.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
		.MuiAutocomplete-inputRoot {
		padding-right: 0;
	}

	.form__upload-logo {
		margin: 25px 0 25px;
		width: 100%;
		.MuiIconButton-root {
			margin: auto;
		}
		.form__upload-label {
			margin: auto;
			width: 90px;
			height: 90px;
			overflow: hidden;
			position: relative;
			&:before {
				content: "";
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				border-radius: 90px;
				left: 0;
				background: $primaryLight;
			}
			cursor: pointer;
			i {
				width: 30px;
				height: 30px;
				display: block;
				margin: auto;
				background: url(../images/upload-logo-icon.svg) center no-repeat;
				background-size: contain;
				position: relative;
			}
			img {
				width: 100%;
				border-radius: 90px;
				height: 100%;
				object-fit: cover;
				object-position: center;
				position: relative;
				background: #f3f3f3;
			}
		}

		.form__upload-label-edit {
			border-radius: 90px;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			cursor: pointer;
			-webkit-transition: all 0.25s cubic-bezier(0.46, 0.82, 0.61, 0.87);
			transition: all 0.25s cubic-bezier(0.46, 0.82, 0.61, 0.87);
			i {
				width: 30px;
				height: 30px;
				display: block;
				margin: auto;
				background: none;
			}
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
				position: relative;
			}
			.form__upload-remove {
				position: absolute;
				top: 3px;
				right: 3px;
				background: url(../images/error-icon.svg) center no-repeat;
				background-size: contain;
				width: 20px;
				height: 20px;
				opacity: 0;
				visibility: hidden;
			}
			&:hover {
				background: #00000080;
				i {
					background: url(../images/edit-logo-icon.svg) center no-repeat;
					background-size: contain;
				}
				.form__upload-remove {
					opacity: 1;
					visibility: visible;
				}
			}
		}
		input {
			display: none;
		}
	}
}

.form__address-container {
	position: relative;
	.form__address-list {
		position: absolute;
		background: #f8f8f8;
		top: 100%;
		width: 100%;
		margin: 0;
		z-index: 999;
		padding: 0;
		.form__address-list-item {
			font-size: 0.9rem;
			padding: 0.6rem 1rem;
			display: block;
			cursor: pointer;
			&:hover {
				background: rgba(0, 0, 0, 0.1);
			}
		}
	}
}
