@import "../../assets/styles/variables";

.discipline__wrapper {
	.lod {
		&__value {
			width: 100%;
			max-width: 16%;
			text-align: start;
			display: flex;
			align-items: center;
			justify-content: flex-start;

			&:first-child {
				max-width: 5%;
			}
		}
	}

	.dicipline-content__wrapper {
		.MuiAccordion-root {
			background-color: transparent;
			&::before {
				background-color: $n150;
			}
			&.Mui-expanded {
				margin: 0px;

				&::before {
					background-color: $n150;
					opacity: unset;
				}
			}
		}
		.category-content {
			// padding-top: $xs;
			// padding-bottom: $xs;
			transition: 0.2s ease;

			.w-101 {
				width: 101% !important;
			}

			&:hover {
				background-color: $rowHover !important;
			}

			&--active {
				background-color: $primaryLighter !important;
				// &:hover {
				// 	background-color: $primaryLighter !important;
				// }
			}

			.MuiAccordionSummary-root {
				padding-top: 14px;
				padding-bottom: 14px;
				padding-left: 0px;
				padding-right: 0px;
				height: fit-content;
				min-height: unset;
				position: relative;

				.MuiAccordionSummary-content {
					cursor: default;
					margin: 0px;
				}
			}
			.MuiAccordionDetails-root {
				padding: 0px;
			}

			&--px-0 {
				padding-left: 0;
				padding-right: 0;
			}

			&--active {
				background: $whiteSmoke;
			}

			// &:not(:last-child) {
			// 	border-bottom: 1px solid $n150;
			// }

			// &__label {
			// 	position: relative;
			// 	display: flex;
			// 	align-items: center;
			// 	&:hover {
			// 		.hover-info {
			// 			display: block;
			// 		}
			// 	}
			// }

			// &__values {
			// 	width: 60%;
			// 	max-width: 327px;
			// 	align-self: flex-end;

			// 	&--labels {
			// 		padding: 25px 0 10px 0;
			// 		margin-right: 30px;
			// 		&--mr-0 {
			// 			margin-right: 0;
			// 		}
			// 	}
			// }

			.lod-content {
				&__value {
					width: 100%;
					max-width: 16px;
					//margin: 0 auto;
					text-align: center;
					display: flex;
					//	align-items: center;
					justify-content: flex-start;

					&:first-child {
						max-width: 5%;
					}
				}
				&__hover {
					z-index: 5;
					position: absolute;
					left: 100px;
					min-width: 488px;
				}

				&__selector {
					// background: none;
					// outline: none;
					// border: none;
					// height: 25px;
					// width: 25px;
					//margin: 0 auto;
					cursor: pointer;
					&--disabled {
						cursor: auto;
					}
					// .radio {
					// 	display: block;
					// 	width: 12px;
					// 	height: 12px;
					// 	box-shadow: 0 0 0 0px $white, 0 0 0 2px $white,
					// 		0 0 0 3px $greySuperLight;
					// 	border-radius: 50%;
					// 	transition: 0.2s ease;
					// 	transition-property: box-shadow;
					// 	outline: none;
					// 	border: none;
					// 	background: white;
					// 	margin: 0 auto;

					// 	&--selected {
					// 		background: $n300;
					// 		box-shadow: 0 0 0 0px $n300, 0 0 0 2px white, 0 0 0 3px $n300;
					// 		&--orange {
					// 			background: $primary;
					// 			box-shadow: 0 0 0 0px $primary, 0 0 0 2px white, 0 0 0 3px $primary;
					// 		}
					// 	}
					// }

					// &--null {
					// 	.radio {
					// 		&--selected {
					// 			background: $n300;
					// 			box-shadow: 0 0 0 0px $n300, 0 0 0 2px white, 0 0 0 3px $n300;
					// 		}
					// 	}
					// }

					// &:hover {
					// 	.radio {
					// 		box-shadow: 0 0 0 0px $white, 0 0 0 2px $white, 0 0 0 3px $grey;
					// 	}
					// }
				}

				.top-position {
					transform: translateY(-100%);
					right: 100px;
					top: -10px;
				}
				.left-position {
					transform: translateX(-100%);
					left: 55px;
				}
				.top-left-position {
					transform: translate(-100%, -100%);
					left: 55px;
				}
			}
		}
	}
}
