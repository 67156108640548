.btn {
	display: flex;
	padding: 8px 24px; // 7px to account for border height
	border-radius: $borderRadiusRegular;
	border: 1px solid transparent;

	font-size: 1rem;
	line-height: 1.5;
	text-align: center;
	font-family: $interSemibold;
	text-decoration: none;
	white-space: nowrap;

	justify-content: center;
	align-items: center;
	cursor: pointer;

	&--stepper {
		padding: 0;
		height: 100%;
		width: 40px;
		border: 1px solid $n200;
		background: $n100;
		&--left {
			left: 0;
			border-radius: $borderRadiusRegular 0 0 $borderRadiusRegular;
		}
		&--right {
			right: 0;
			border-radius: 0 $borderRadiusRegular $borderRadiusRegular 0;
		}
		&:not(:disabled):hover {
			background-color: $n200;
			border-color: $n300;
		}

		&:disabled {
			cursor: not-allowed;
		}

		&--aside {
			height: 50%;
			left: 0;
			color: $n300;
			&.btn--stepper--aside--top {
				top: 0;
				border-radius: $borderRadiusSmall 0 0 0;
				border-bottom: none;
			}
			&.btn--stepper--aside--bottom {
				bottom: 0;
				border-radius: 0 0 0 $borderRadiusSmall;
			}
		}
	}

	&--small {
		//font-size: 0.875rem;
		border-radius: $borderRadiusRegular;
		padding: 2px $regular;
	}

	&::before,
	&::after {
		background-size: contain;
		background-repeat: no-repeat;
		position: relative;
		height: 12px;
		width: 18px;
		display: inline-flex;
	}

	&::before {
		margin-right: 10px;
	}

	&::after {
		margin-left: 10px;
	}

	&--primary {
		background-color: $n500;
		color: $n000;

		&:not(:disabled):hover {
			box-shadow: 0px 12px 20px -8px transparentize($n500, 0.84);
		}

		&:not(:disabled):active {
			background-color: $n150;
			border-color: $n400;
			color: $n400;
		}

		&:not(:disabled):focus {
			box-shadow: 0px 0px 0px 1.5px $n300;
			border-color: $n000;
		}
		&:disabled {
			svg * {
				fill: $n300;
			}
		}
		&.btn--disabled {
			background-color: $n150;
			color: $n300;
			cursor: not-allowed;
			pointer-events: none;
		}
	}

	&--secondary {
		background-color: $n000;
		border-color: $n200;
		color: $n500;

		&:not(:disabled):not(.btn--disabled):hover {
			border-color: $n300;
		}

		&:not(:disabled):not(.btn--disabled):active {
			background-color: $n150;
			border-color: $n300;
			color: $n500;
		}

		&:not(:disabled):not(.btn--disabled):focus {
			box-shadow: 0px 0px 0px 1.5px $n200;
			border-color: $n300;
		}
		&:disabled,
		&.btn--disabled {
			background-color: $n150;
			color: $n300;
		}
	}

	&--tertiary {
		background-color: $n000;
		color: $n500;
		border-color: $n150;

		&.border-none {
			border-color: transparent;
		}

		&.bg-none {
			background-color: transparent;
		}

		&:hover {
			background-color: $n100;
			border-color: $n150;
		}

		&:active {
			background-color: $n150;
			border-color: $n300;
			color: $n500;
		}

		&:focus:not(.btn--disabled) {
			box-shadow: 0px 0px 0px 1.5px $n200;
			border-color: $n500;
		}

		&:disabled,
		&.btn--disabled {
			background-color: $n150;
			color: $n300;
			border-color: $n150;
		}

		&--success {
			border-color: $success;
		}
	}

	&--success {
		height: 40px;
		background-color: $success;
		color: $n000;

		&:hover {
			background-color: $successDark;
		}

		&:active {
			background-color: $successLight;
			border-color: $successDark;
			color: $successDark;
		}

		&:focus {
			box-shadow: 0px 0px 0px 1.5px $success;
			border-color: $successLight;
		}
	}

	&--error {
		background-color: $error;
		color: $n000;

		&:hover {
			background-color: $errorDark;
		}

		&:active {
			background-color: $errorLight;
			border-color: $errorDark;
			color: $errorDark;
		}

		&:focus {
			box-shadow: 0px 0px 0px 1.5px $error;
			border-color: $errorLight;
		}
	}

	&--orange {
		background-color: $primary;
		color: $n500;

		&:hover {
			background-color: $primaryDark;
		}

		&:active {
			background-color: $primaryLight;
			border-color: $primaryDark;
			color: $primaryDark;
		}

		&:focus {
			box-shadow: 0px 0px 0px 1.5px $primary;
			border-color: $primaryLight;
		}
	}

	&--editor {
		padding: 2px $regular;
		font-size: 0.875rem;
		line-height: 1.7;
		color: $n300;
		background: none;
		.header-one {
			font-size: 1.5rem;
		}
		.header-two {
			font-size: 1.25rem;
		}
		.header-three {
			font-size: 1rem;
		}
		.header-four {
			font-size: 0.75rem;
		}

		&--active {
			color: $n500;
			background-color: $n150;
			border-radius: $borderRadiusSmall;
			font-family: $interSemibold;
			svg * {
				fill: $n500;
			}
		}
		&:hover {
			color: $n500;
			svg * {
				fill: $n500;
			}
		}
	}
	&--secondary-error {
		background-color: $n000;
		border-color: $red;
		color: $red;

		&:hover {
			background-color: $red;
			color: $n000;
		}

		&:active {
			background-color: $redDark;
			color: $n000;
		}
	}

	&:disabled,
	&--disabled {
		cursor: not-allowed;
	}
	&--secondary-error,
	&--primary,
	&--success,
	&--error {
		&:disabled {
			border: none;
			background-color: $n200;
			color: $n300;
			cursor: not-allowed;
		}
	}

	$icons: "add" "plus.svg" currentColor "" 24px 24px,
		"upload" "upload.svg" currentColor "" 24px 24px,
		"send-invitation" "send-invitation.svg" $primary,
		"send-message" "send-invitation.svg" $n000 $n000 $regular $regular,
		"prev" "arrow-left-icon.svg" currentColor,
		"first" "arrow-first-icon.svg" currentColor,
		"next" "arrow-right-icon.svg" currentColor,
		"last" "arrow-last-icon.svg" currentColor,
		"bimify" "bimify-white-icon.svg" currentColor,
		"checkmark" "checkmark.svg" $n300,
		"checkmark-checked" "checkmark.svg" $success,
		"checkmark-outlined" "checkmark-outlined.svg" $n300 $n500,
		"checkmark-outlined-checked" "checkmark-outlined.svg" $success,
		"download" "download.svg" $n300 $n500 20px 20px,
		"download-darker-large" "download.svg" $n500 $n500 20px 15px,
		"download-24" "download.svg" currentColor "" 24px 24px,
		"download-disabled" "download.svg" $n300 $n300,
		"download-large-disabled" "download.svg" $n300 $n300 20px 15px,
		"download-dark" "download.svg" $n300 $n500,
		"download-n500" "download.svg" $n500 $n300 20px 20px,
		"email--outlined" "email-outlined-icon.svg" $n200 $n300,
		"email--outlined--white" "email-outlined-icon.svg" $n000 $n200,
		"email" "email-icon.svg" $n000 $n300,
		"options" "options-icon.svg" $n200 $n300,
		"edit" "edit.svg" $n300 $n500 16px 16px,
		"embed" "embed-icon.svg" $n200 $n300,
		"attach" "attach-icon.svg" $n500 $n500 22px 11px,
		"calendar" "calendar.svg" $n500 $n500 16px 16px,
		"close" "close-icon.svg" $n300 $n500, "trash" "trash.svg" $red,
		"time" "time.svg" $n300 $n500,
		"close--white" "close-icon.svg" $n000 $n200 10px 10px,
		"trash-large" "trash.svg" $red $n000 24px 24px,
		"time" "time.svg" $n300 $n500, "trash-disabled" "trash.svg" $n300 $n300,
		"trash-large-disabled" "trash.svg" $n300 $n300 20px 15px,
		"price" "price.svg" $n200 $n400, "price--active" "price.svg" $n500 $n500,
		"grid-view" "grid-view.svg" $n300 $n300 30px 30px,
		"list-view" "list-view.svg" $n300 $n300 30px 30px,
		"eye" "eye.svg" $n200 $n300 24px 24px,
		"eye-primary" "eye.svg" $primary 24px 24px;

	@each $modifier, $path, $color, $secondary, $height, $width in $icons {
		&--#{$modifier}:before,
		&--#{$modifier}:after {
			-webkit-mask: url(../images/icons/misc/#{$path}) no-repeat 50% 50%;
			mask: url(../images/icons/misc/#{$path}) no-repeat 50% 50%;
			background-color: $color;
			@if $height {
				height: $height !important;
			}
			@if $width {
				width: $width !important;
			}
		}

		@if $secondary {
			&--#{$modifier}--active:before,
			&--#{$modifier}--active:after {
				background-color: $secondary;
				&:hover {
					&:before,
					&:after {
						background-color: $primary;
					}
				}
			}
			&--#{$modifier}:hover {
				&:before,
				&:after {
					background-color: $secondary;
				}
			}
		}

		&--#{$modifier}:not(.btn--primary):disabled {
			&:before,
			&:after {
				background-color: $n200;
			}
		}

		&--#{$modifier}.btn--primary:disabled {
			&:before,
			&:after {
				background-color: $n100;
			}
		}
	}

	&--icon {
		padding-left: 17px;
		padding-right: 17px;
		&:before,
		&:after {
			display: none;
			content: "";
			-webkit-mask-size: contain;
			mask-size: contain;
			height: 12px;
			width: 12px;
		}
		&--before {
			&:before {
				display: block;
				margin-right: 10px;
			}
			&.mr-4:before {
				margin-right: 4px;
			}
			&.ml-2-5:before {
				margin-left: 2.5px;
			}
			&.mr-12-5:before {
				margin-right: 12.5px;
			}
			&.mr-15:before {
				margin-right: 15px;
			}
		}
		&--after:after {
			display: block;
			margin-left: 10px;
		}
		&--solo {
			height: 40px;
			width: 40px;
			padding-left: 0;
			padding-right: 0;
			&:before,
			&:after {
				margin: 0;
			}
			&--xs {
				width: 20px;
				height: 20px;
			}
			&--small {
				width: 28px;
				height: 28px;
			}
			&--medium {
				width: 32px;
				height: 32px;
			}
		}
		&--red {
			&:before,
			&:after {
				background-color: $red;
			}
		}

		&--primary {
			&:before,
			&:after {
				background-color: $primary;
			}
		}
		&--n300 {
			&:before,
			&:after {
				background-color: $n300;
			}
		}
		&--n500 {
			&:before,
			&:after {
				background-color: $n500;
			}
		}
		&--n000 {
			&:before,
			&:after {
				background-color: $n000;
			}
		}
		&--large {
			&:before,
			&:after {
				min-height: 22px;
				min-width: 22px;
				width: auto;
				height: auto;
			}
		}

		&--xxl {
			&:before,
			&:after {
				min-height: 22px;
				min-width: 32px;
				width: auto;
				height: auto;
			}
		}

		&--medium {
			&:before,
			&:after {
				min-height: $regular;
				min-width: $regular;
				width: auto;
				height: auto;
			}
		}
	}

	&--loading {
		&::after {
			content: "";
			display: none;
			height: 14px;
			width: 14px;
			border: 1px solid $blueDark;
			border-radius: 50%;
			border: 1px solid white;
			border-top: 1px solid $blueDark;
			animation: spin 2s linear infinite;
		}
		&.border-n500 {
			&::after {
				border: 1px solid $n500;
				border-top: 1px solid white;
			}
		}
		&--active {
			&::after {
				display: inline;
			}
		}

		@keyframes spin {
			0% {
				transform: rotate(0deg);
			}
			100% {
				transform: rotate(360deg);
			}
		}
	}

	&--width-auto {
		min-width: unset;
	}

	&--stripped,
	&--link {
		background: none;
		border: none;
		outline: none;
		padding: 0;
		margin: 0;
		height: unset;
		width: unset;
		&--block {
			text-align: left;
			white-space: break-spaces;
		}
	}

	&--usertab {
		cursor: pointer;
		&:not(:disabled):hover {
			opacity: 0.8;
		}
	}

	&--link {
		color: $primary;
		text-decoration: underline;
	}

	&--deliverable {
		&__wrapper {
			border: 1px solid $n150;
			height: 50%;
			min-height: calc(158px / 2);
			width: calc(158px / 2);
			min-width: 50%;
			border-radius: 0;
			border-top: none;
			border-right: none;
			&:first-child {
				border-top-right-radius: $borderRadiusSmall;
			}
			&:last-child {
				border-bottom: none;
				border-bottom-right-radius: $borderRadiusSmall;
			}
		}
		border-color: transparent;
		height: 100%;
		width: 100%;

		&:disabled {
			border-radius: 0;
		}
	}

	&--edit-solo,
	&--price {
		padding: 6px;
		background-color: transparent;
		border-radius: $borderRadiusSmall;
		&:hover {
			cursor: pointer;
			background-color: $n100;
			.icon--target-fill {
				svg * {
					fill: $greySuperDark;
				}
			}
		}
		&--active,
		&:active {
			background-color: $n150;
			.icon--target-fill {
				svg * {
					fill: $greySuperDark;
				}
			}
		}
	}

	&--edit {
		border-radius: $borderRadiusRegular;
		padding: 2px $regular;
		padding-left: 10px;
	}

	&--eye {
		width: 40px;
		height: 40px;
		&:hover {
			background-color: rgba(0, 0, 0, 0.04);
		}
	}
	&--assign {
		color: $n000;
		&::before {
			content: "";
			background-image: url(../images/button-icons/assign.svg);
			background-color: $n000;
			color: $n000;
			height: 15px;
			width: 15px;
		}
	}

	&--re-assign {
		color: $n000;
		&::before {
			content: "";
			background-image: url(../images/button-icons/re-assign.svg);
			height: 15px;
			width: 15px;
		}
	}

	&--reject,
	&--archive {
		background-color: $red;
		color: $n000;
		&:hover {
			box-shadow: 0px 0px 20px $greySuperLight;
		}
		&:active {
			background-color: $redDark;
		}
	}

	&--archive {
		&::before {
			content: "";
			background-image: url(../images/button-icons/archive-icon.png);
		}
	}

	&.link {
		text-decoration: none;
	}

	&--top {
		position: fixed;
		right: $regular;
		bottom: 32px;
		padding: 7px;
		border-radius: 50%;
	}

	&--copy {
		position: absolute;
		right: $m;
		top: $m;
		border-radius: 50%;
		padding: 7px;
		z-index: 1;
	}

	// EVERYTHING UNDERNEATH NEEDS TO BE REVISED UPDATED FOR FUTURE REFERENCE AND
	// UPDATED TO CORRESPOND TO THE ABOVE DECLARED FORMAT

	&--width-narrow {
		min-width: 90px;
	}

	&--width-medium {
		min-width: 120px;
	}

	&--width-160 {
		min-width: 160px;
	}

	&.btn--fluid,
	&--wide {
		min-width: 100%;
		font-size: 1rem;
		line-height: 1.3125;
	}

	&--trashed,
	&--alert {
		&:hover,
		&:active {
			color: $n000;
			border-color: transparent;
		}
		&:hover {
			background-color: $red;
		}
		&:active {
			background-color: $redDark;
		}
	}
	&--trashed {
		background-color: $red;
		color: $n000;
		&::before {
			content: "";
			background-image: url(../images/button-icons/trash-icon-white.svg);
		}
	}

	&--esc {
		border-color: $blueDark;
	}

	&--accept,
	&--activate,
	&--green {
		background-color: $green;
		color: $n000;
		&:hover {
			box-shadow: 0px 0px 20px $greySuperLight;
		}
		&:active {
			background-color: $greenDark;
		}
	}

	&--accept--checkmark {
		&::before {
			content: "";
			background-image: url(../images/button-icons/checkmark-icon--white.png);
		}
	}

	&--activate {
		&::before {
			content: "";
			background-image: url(../images/button-icons/activate-icon.png);
		}
	}

	&--restore {
		background-color: $green;
		color: $n000;
		&:active {
			background-color: $greenDark;
		}
		&::before {
			content: "";

			background-image: url(../images/button-icons/restore-icon-white.svg);
		}
	}

	&--esc {
		&:hover {
			color: $n000;
			background-color: $blueDark;
		}
		&:hover:before {
			background-image: url(../images/button-icons/esc-white-icon.png);
		}
		&:focus {
			color: $n000;
			background-color: black;
		}
	}

	&--esc {
		&::before {
			content: "";
			background-image: url(../images/button-icons/esc-dark-icon.png);
		}
	}

	&--save {
		color: $n000;
		background-color: $blueDark;
		&:active {
			background-color: black;
		}
	}

	&--save {
		&::before {
			content: "";
			background-image: url(../images/button-icons/save-icon.png);
		}
	}

	&--stripped.color-orange {
		&:hover {
			color: $primaryDark;
		}
	}

	&--hover {
		&--fill:hover {
			svg * {
				fill: $primary;
			}
		}
		&--stroke:hover {
			svg * {
				stroke: $primary;
			}
		}
	}
}
