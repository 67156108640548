/* Login component style **/

@import "../../assets/styles/variables";

.auth-wrapper {
	.content {
		width: 100%;
		max-width: 380px;
		&--signup {
			max-width: 770px;
			.MuiAccordionDetails-root {
				padding: 32px;
			}
		}
	}

	.bg {
		height: 100%;
		background-image: url(../../assets/images/login-bg.png);
		background-position: 38% center;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;
		&:before {
			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background-color: #151f27;
			opacity: 0.8;
		}
	}

	&__logo {
		max-width: 250px;
		width: 100%;
	}
}
