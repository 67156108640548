@import "../../../assets/styles/variables";

.single-building__bimify {
	.floor__wrapper {
		&:last-child {
			//	padding-bottom: 32px;
		}
	}

	.floor-bg {
		padding: 10px $m;
		// border: 4px solid $n300;
		border-top: 4px solid $n500;
		position: relative;
		&--upper {
			border-bottom: none;
			&:before,
			&:after {
				content: "";
				position: absolute;
				width: 4px;
				height: 100%;
				background-color: $n300;
				top: 0;
			}
			&:before {
				left: 0;
			}
			&:after {
				right: 0;
			}
		}
		&--lower {
			padding: 10px 9px;
			border: 4px solid $n500;
			border-bottom: none;
			position: relative;
			&.bb-n500 {
				border-bottom: 4px solid $n500;
			}
			&.bt-n300 {
				border-top-color: $n300;
				&:before {
					content: "";
					position: absolute;
					top: -4px;
					width: calc(100% + 8px);
					left: -4px;
					background-color: #7f909f;
					z-index: 1;
					height: 4px;
				}
			}
		}
		&--roof {
			border-top: none;
		}
		.upper-floor-additional-border {
			// used for the small side borders on top of the floor element
			position: absolute;
			top: 0;
			width: 100%;
			height: 4px;
			border: 4px solid $n500;
			border-top: 0;
			border-bottom: 0;
			left: 0;
			right: 0;
			z-index: 1;
		}
	}

	.roof-edges {
		display: block;
		width: 100%;

		clip-path: polygon(50% 0%, -5% 100%, 105% 100%);
		background-size: cover;
		background-position: center;
		position: relative;
		margin-bottom: -1px;
		background-color: $n500;
		.roof-bg {
			width: calc(100% - 8px);
			height: calc(100% - 4px);
			clip-path: polygon(50% 0%, -2% 100%, 102% 100%);
			bottom: 0;
			position: absolute;
			left: 4px;
			top: 4px;
		}
	}
	.floor-elevation__wrapper {
		position: relative;
		border-bottom: 1px solid $n500;
		&:after {
			width: 7px;
			height: 7px;
			border-radius: 50%;
			display: block;
			content: "";
			background-color: $n500;
			position: absolute;
			right: 0;
			bottom: -4px;
		}
		&--no-border {
			border: none;
			&:after {
				content: none;
			}
		}
	}

	.ground-floor-label {
		color: $n300;
	}
}
