@import "../../../assets/styles/variables";

.page-404 {
	max-width: 484px;
	width: 100%;
	margin: 0 auto;
}
.page-server-error {
	max-width: 800px;
	width: 100%;
	margin: 0 auto;
}
