.badge__wrapper {
	min-width: 20px;
	min-height: 20px;

	&--invisible {
		min-width: 0;
		min-height: 0;
	}

	.badge {
		background-color: $primary;
		min-width: $m;
		height: $m;
		border-radius: 50%;
		top: $xxs;
		right: $xxs;

		&::after {
			content: "";
			opacity: 0.6;
			position: absolute;
			border-radius: 50%;
			height: 30px;
			width: 30px;
			box-shadow: inset 0 0 4px 4px $primary;
			animation: pulse-ring 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
		}

		&--invisible {
			display: none;
		}

		&--with-content {
			background-color: $primary;
			height: 20px;
			border-radius: $borderRadiusLarge;
			right: -10px;
			color: $n000;
			padding: $xxs;
			transform: none;
			min-width: 40px;

			&:before,
			&:after {
				display: none;
			}

			&.bg-red {
				background-color: $red;
			}

			&.bg-g300 {
				background-color: $g300;
			}

			&.files-upload-indicator {
				min-width: 25px;
				padding: 2px $s;
				height: 20px;
			}

			&.uncategorized-indicator {
				min-width: unset;
				padding: $xxs $s;
				height: 24px;
			}

			&.chat-indicator {
				min-width: 20px;
				right: 3px;
				top: -3px;
			}

			&.list-indicator {
				top: -10px;
				right: -10px;
				left: unset;
				min-width: 20px;
			}

			&.text-info {
				margin-left: $s;
				width: auto;
				height: 20px;
				padding: 2px $s;
				@extend h6;
				color: $n500;
				background-color: $orangeLight;
			}
			&::after {
				display: none;
			}
		}

		&--relative {
			position: relative;
			right: 0;
			margin-left: 2px;
		}

		&--transform-none {
			transform: scale(1) translate(0, 0);
			margin-left: $xs;
			top: 0;
		}
	}
}
