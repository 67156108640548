.logo__wrapper {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	border: 1px solid $n000;
	padding: 1px;
	box-shadow: 0px 6px 8px -4px rgba(27, 32, 36, 0.16);

	&--placeholder {
		border: none;
		box-shadow: none;
	}

	&--stripped {
		padding: 0;
	}

	&--20 {
		width: 20px;
		height: 20px;
	}

	&--small {
		width: 24px;
		height: 24px;
	}
	&--32 {
		width: 32px;
		height: 32px;
	}
	&--36 {
		width: 36px;
		height: 36px;
	}
	&--medium {
		width: 40px;
		height: 40px;
	}

	&--large {
		// width: 72px;
		// height: 72px;
		width: 68px;
		height: 68px;
	}

	&--x-large {
		width: 120px;
		height: 120px;
	}

	// &--grayscale {
	// 	filter: grayscale(1);
	// 	background-color: $n400;
	// }

	&--hoverable-icon {
		.target-fill,
		.target-stroke {
			transition: $transition1;
		}

		&:hover {
			.target-fill {
				fill: $n300;
			}
			.target-stroke {
				stroke: $n300;
			}
		}
	}
}

.logo {
	height: 100%;
	width: 100%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100% auto;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background-size: contain;

	&--cover {
		background-size: cover;
	}
}
