.InputFile__wrapper {
	border: 0;
	display: flex;
	background: none;
	border-radius: 10px;
	padding: 8px;
	cursor: pointer;
	vertical-align: middle;
	flex-shrink: 0;
	align-items: center;
	justify-content: space-between;
}
.InputFile__label {
	&:hover {
		cursor: pointer;
	}
}
