@import "../variables";

$alignContent: (
	"start": flex-start,
	"end": flex-end,
	"center": center,
	"between": space-between,
	"around": space-around,
);

@each $label, $value in $alignContent {
	.align-content-#{$label} {
		align-content: $value;
	}
	.justify-content-#{$label} {
		justify-content: $value;
	}
	.align-items-#{$label} {
		align-items: $value;
	}
}

@each $label, $value in $alignContent {
	@include mq("tablet") {
		.align-content-tablet-#{$label} {
			align-content: $value;
		}
		.justify-content-tablet-#{$label} {
			justify-content: $value;
		}
		.align-items-tablet-#{$label} {
			align-items: $value;
		}
	}
}

@each $label, $value in $alignContent {
	@include mq("desktop") {
		.align-content-desktop-#{$label} {
			align-content: $value;
		}
		.justify-content-desktop-#{$label} {
			justify-content: $value;
		}
		.align-items-desktop-#{$label} {
			align-items: $value;
		}
	}
}

$textAlign: (
	"center": center,
	"left": left,
	"right": right,
);

@each $label, $value in $textAlign {
	.text-#{$label} {
		text-align: $value;
	}
}
@each $label, $value in $textAlign {
	@include mq("tablet") {
		.text-tablet-#{$label} {
			text-align: $value;
		}
	}
}
@each $label, $value in $textAlign {
	@include mq("desktop") {
		.text-desktop-#{$label} {
			text-align: $value;
		}
	}
}

$textTransform: (
	"uppercase": uppercase,
	"capitalize": capitalize,
);

@each $label, $value in $textTransform {
	.text--#{$label} {
		text-transform: $value;
	}
}
