@import "../../../assets/styles/_variables.scss";

.linear-progress {
	.MuiLinearProgress-barColorPrimary {
		background-color: $primary;
	}

	&--done {
		.MuiLinearProgress-barColorPrimary {
			background-color: $green;
		}
	}
}
