@import "./variables";
@import "./parts/typography";
@import "./placeholderStyles";

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
input:-internal-autofill-selected {
	background-color: $n000 !important;
	border-color: $n300 !important;
	color: $n500 !important;
	-webkit-box-shadow: 0 0 0px 1000px white inset !important;
	box-shadow: 0 0 0px 1000px white inset !important;
}

//Hide number arrows on input:number
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

.default-form {
	.form__title {
		font-family: $openSansBold;
		font-size: 16px;
		text-align: center;
	}

	.form .MuiInputBase-input,
	.form .MuiInputLabel-root {
		padding-left: 25px;
	}

	.form-section {
		margin-top: 80px;
		&.mt-0 {
			margin-top: 0;
		}
		&__title {
			font-size: 0.75rem;
			color: $pinkSwan;
			font-family: $openSansRegular;
		}
		.error-message {
			opacity: 0;
			color: $redDark;
			transition: 0.4s ease;
			transition-property: opacity;
			font-size: 0.88rem;
		}
		.h-divider {
			background-color: $greyLight;
			position: relative;
			&::before {
				position: absolute;
				content: "";
				width: 0;
				height: 100%;
				left: 0;
				background-color: $redDark;
				transition: 0.6s ease;
				transition-property: width;
			}
		}
		&--error {
			.form-section__title {
				color: $redDark;
			}
			.error-message {
				opacity: 1;
			}
			.h-divider {
				&::before {
					width: 100%;
				}
			}
		}
	}

	.target-input {
		&--w-100 {
			.MuiInput-formControl {
				width: 100%;
			}
			.MuiInputAdornment-positionStart {
				margin-right: 0;
			}
			.MuiInputBase-input {
				padding-left: 0;
			}
		}
	}

	&.form {
		.form__input--address {
			.MuiInputLabel-root,
			.MuiInputBase-input {
				padding-left: 20px;
			}
		}

		.MuiSelect-select.MuiSelect-select {
			align-items: center;
			justify-content: flex-start;
			display: flex;
		}

		&.no-wrap {
			flex-wrap: nowrap;
		}
	}

	.row {
		margin-left: -15px;
		margin-right: -15px;
		width: calc(100% + 30px);
	}

	.w-25 {
		flex: 0 0 25%;
		max-width: 25%;
	}

	.w-33 {
		flex: 0 0 33.33%;
		max-width: 33.33%;
	}

	.form__input {
		&--135 {
			width: 135px;
		}

		&--195 {
			width: 195px;
		}

		&--50 {
			width: 50%;
		}

		&--full {
			width: 100%;
		}
	}

	// Remove input Arrows/Spinners

	.hide-input-spin-buttons input::-webkit-outer-spin-button,
	.hide-input-spin-buttons input::-webkit-inner-spin-button {
		/* Chrome, Safari, Edge, Opera */

		-webkit-appearance: none;
		margin: 0;
	}

	.hide-input-spin-buttons input[type="number"] {
		-moz-appearance: textfield;
	}
}

.standardized-form {
	.MuiInputBase-root {
		font-size: 0.875rem;
	}

	.form-contents__wrapper {
		max-width: 100%;
	}
	.button-contents__wrapper {
		max-width: 100%;
		width: auto;
		justify-content: end;
	}
	.form-row {
		width: calc(100% + 12px);
		margin-left: -6px;
		margin-right: -6px;
		display: flex;
		flex-wrap: wrap;
		.form-column {
			padding: 0 6px;
		}
	}

	.label {
		color: $n500;
		padding-left: 3px;
		font-family: $interBold;
		font-size: 0.875rem;
		line-height: 1.7;
		display: flex;
		overflow: visible;
		align-items: center;
		// MUI native behavior overwrite

		position: relative;
		transform: none;
		white-space: nowrap;
		.MuiFormLabel-asterisk {
			display: none;
		}
		&--required {
			&__asterisk {
				font-family: $openSansRegular;
				color: $error;
				margin-left: 4px;
			}
		}

		&--focused {
			color: $n500 !important;
		}

		&__tooltip {
			pointer-events: visible;

			display: inline-flex;
			.icon--info svg {
				height: 12px;
				width: auto;
				* {
					fill: $n300;
				}
			}
		}
		&__note {
			margin-left: 4px;
			display: inline-flex;
			font-family: $interItalic;
			font-size: 0.75rem;
			color: $n300;
		}
		& + .MuiInput-formControl,
		& + .MuiFormControl-root {
			margin-top: 0;
		}

		& + .MuiFormControl-root {
			margin-bottom: 0;
		}
	}

	.label.MuiFormLabel-root {
		@extend .label;
	}

	.MuiFormLabel-root.Mui-focused {
		@extend .label--focused;
	}

	.form__input {
		position: relative;

		&__placeholder {
			font-size: 0.875rem;
			padding: 0 !important;
			opacity: 1 !important;
		}

		.MuiInput-underline:before,
		.MuiInput-underline:after {
			display: none;
		}

		.field__root {
			background-color: $n000;
			color: $n300;
			box-sizing: border-box;
			min-height: 40px;
			height: 40px;
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-top: 0px;

			&.height-unset {
				height: unset;
			}

			// &.Mui-focused:not(.field__root--status) {
			// 	border-color: $primary !important;
			// }

			.MuiSelect-select {
				padding-right: 10px;
				&:focus {
					background: transparent;
				}
			}

			&--status {
				min-width: 160px;
				width: 160px;
				display: flex;
				justify-content: flex-start;
				font-size: 0.875rem;
				color: $n300;
				overflow: visible;
				display: flex;
				//justify-content: center;
				align-items: center;
				border-radius: 6px;
				border-width: 1px;
				border-style: solid;
				padding: 8px !important;
				padding-left: 12.5px !important;
				//	padding-right: 35.5px !important;

				&.px-24 {
					padding-left: 24px !important;
					//padding-right: 24px !important;
				}

				&--uncategorised {
					border-color: $n400;
					color: $n400;
					background-color: $n150;
				}

				&--archived {
					border-color: $n400;
					color: $n400;
					background-color: $n100;
				}
				&--qa,
				&--customisation,
				&--offered {
					border-color: $blue;
					color: $blue;
					background-color: $blueLight;
				}

				&--ongoing,
				&--normalisation,
				&--done,
				&--pending,
				&--requested {
					border-color: $primary;
					color: $primary;
					background-color: $orangeLighter;
				}

				&--reviewed,
				&--categorised,
				&--delivered,
				&--resolved,
				&--completed,
				&--accepted,
				&--active {
					border-color: $green;
					color: $green;
					background-color: $greenLight;
				}
				&--incomplete,
				&--declined {
					border-color: $red;
					color: $red;
					background-color: $redLight;
				}
			}

			&--multiline {
				min-height: 160px;
				resize: vertical;
				&.small {
					min-height: 40px;
				}
			}
			&--small {
				min-height: 24px;
			}

			&--p-default {
				padding: 1px 2px;
				text-align: center;
			}

			&:not(.field__root--stepper):not(.field__root--pagination):not(
					.field__root--editor
				):not(.field__root--calendar):not(.field__root--adorned-end):not(
					.field__root--adorned-start
				):not(.field__root--status):not(.field__root--p-default) {
				padding: 8px 18px 8px 12px !important;
				// min-width: 320px;
				width: 100%;
			}

			&.field__select {
				// to account for select arrow icon width
				padding-right: 30px !important;
			}

			max-width: 100%;
			border-radius: $borderRadiusRegular;
			&:hover,
			&:focus,
			&:active {
				border-radius: $borderRadiusRegular;
			}
			border: 1px solid $n150;
			&:hover {
				border-color: $n200;
			}
			&:focus:not(.field__root--status) {
				border-color: $primary;
				color: $n500;
				background-color: $n000;
			}

			&--complete {
				background-color: $n000;
				border-color: $n300;
				color: $n500;
			}
			&--error {
				border-color: $error;
				color: $n500;
			}

			&--stepper {
				padding: 8px 44px !important;
				text-align: center;
				border-color: $n200;
				&__wrapper {
					width: 120px;
					&--aside {
						width: 80px;
					}
				}
				&.aside {
					padding: 8px 8px 8px 44px !important;
				}
			}

			&--pagination {
				// important to overwrite native mui styles
				padding: $s $xs !important;
				text-align: center;
				border-color: $n200;
				&__wrapper {
					width: 40px;
				}
			}

			&--calendar {
				padding-left: 50px !important;
			}

			&--phone input {
				padding: 0;
			}

			&--disabled,
			&:disabled {
				background-color: $n150;
				border-color: $n300;
				color: $n300;
				cursor: default;

				.MuiInputBase-root {
					color: $n300;
				}
				&:hover {
					pointer-events: none;
				}
				* {
					pointer-events: none;
				}
			}
		}

		.field__select__icon {
			position: absolute;
			right: 5px;
			display: flex;
		}

		.field__root--adorned-start {
			padding: 8px 12px 8px 32px !important;
			width: 100%;
			&.field__root--adorned-start--pin {
				padding-left: 35px !important;
			}
		}

		.field__root--adorned-end {
			padding: 8px 35px 8px 12px !important;
			width: 100%;
			&.text-right {
				text-align: right;
			}
			&.field__root--adorned-end--percentage {
				text-align: left;
			}
		}
		&--date {
			.MuiFormControl-marginNormal {
				margin: 0px;
			}
		}
	}

	.form__error {
		color: $error;
		font-size: 0.75rem;
		line-height: 1.667;
		margin-top: 4px;
		font-family: $interItalic;
	}

	.MuiInputBase-root.strip-native-styles {
		margin: 0;
		padding: 0;
		border: none;
		&.my-neg-xxs {
			@extend .my-neg-xxs;
		}
	}

	.adornment__root--calendar {
		color: $n300;
		margin: 0 !important;
		position: absolute;
		left: 14px;
		min-height: 100%;
		top: 0;
		.calendar-button {
			margin-left: 0;
			padding: 0;
			.icon {
				height: 100%;
				align-items: center;
				justify-content: center;
				display: flex;
				padding-bottom: 2px;
			}
		}
	}

	.adornment {
		&__root {
			color: $n300;
			margin: 0 !important;
			&--start {
				position: absolute;
				left: 18px;
				width: 14px;
			}
			&--end {
				position: absolute;
				right: 18px;
			}
			&__stepper,
			&__pagination {
				height: 100% !important;
				max-height: unset !important;
				position: absolute;
				&--start {
					left: 0;
					&--aside {
						display: flex;
						flex-direction: column;
					}
				}
				&--end {
					right: 0;
				}
			}
			&--email {
				* {
					color: $n300;
				}
			}
		}
	}

	.adornment__root--start
		+ .field__root:not(.field__root--stepper):not(.field__root--pagination):not(
			.field__root--editor
		):not(.field__root--calendar) {
		padding-left: calc(18px * 2 + 14px) !important;
	}

	.form__address-list {
		position: absolute;
		z-index: 5;
		top: 100%;
		left: 0;
		width: 100%;

		padding: 8px 4px;
		margin-top: 4px;
		list-style: none;
		background-color: $n000;
	}

	.checkbox__wrapper {
		margin: 0;
		.checkbox__label {
			font-size: 0.875rem;
			line-height: 1.7;
		}
		.checkbox {
			display: inline-flex;
			height: 16px;
			width: 16px;
			color: $n000;
			box-sizing: border-box;
			padding: 3px;
			border-radius: $borderRadiusSmall;
			margin-right: 8px;
			background-size: 11px auto;
			background-repeat: no-repeat;
			background-position: center;
			transition: 0.2s all;
			box-shadow: inset 0 0 0 1px $n200;
			&--small {
				height: 12px;
				width: 12px;
			}
			.icon,
			svg {
				display: none;
			}
			&--selected {
				background-color: $primary;
				box-shadow: inset 0 0 0 2px $primary;
				.icon {
					display: flex;
					align-items: center;
					height: 100%;
				}
				svg {
					max-height: 100%;
					max-width: 100%;
					display: flex;
					* {
						stroke: $white;
					}
				}
			}
		}
		&--disabled {
			cursor: not-allowed !important;
			color: $n200;
			.checkbox {
				background-color: $n000;
				box-shadow: inset 0 0 0 2px $n200;
				&--selected {
					background-color: $n200;
				}
			}
		}
		&:not(.checkbox__wrapper--disabled):hover {
			.checkbox {
				box-shadow: inset 0 0 0 1px $primaryLight;
				background-color: $n000;
				&--selected {
					background-color: $primary;
					box-shadow:
						inset 0 0 0 1px $primary,
						inset 0 0 0 2px $primaryLight;
				}
			}
		}
	}

	.cp-label,
	.label {
		&__root {
			&--multiline {
				top: 10.5px;
				transform: none;
			}
			&.d-none {
				display: none;
			}
		}

		&--error {
			color: $red;
		}
	}

	.password-adornment__root {
		margin: 0;
		padding: 5px;
		&--error {
			svg {
				fill: $red;
			}
		}
	}

	.form__error {
		text-align: left;
		font-size: 0.75rem;
		color: $red !important;
		line-height: 14px;
		display: block;
		padding-top: 0.1rem;
		padding-left: 0.5rem;
		left: 0;
		&--bottom {
			top: calc(100% + 2px);
			position: absolute;
		}
	}
}

.standardized-form .form__input .field__root--editor {
	@extend h2 !optional;
	border-color: transparent;
	position: relative;
	background-color: transparent;
	color: $n300;
	padding: 5px 18px 0px 12px !important;
	&:hover {
		border-color: transparent;
	}
	&.field__root--complete {
		color: $n500;
	}
}

.phone__dropdown-list {
	ul {
		max-height: 400px;

		padding: 8px 4px;
		margin-top: 4px;
	}
	li {
		padding: 12px;
		border-radius: $borderRadiusSmall;
		cursor: pointer;
		transition: 0.3s ease;
		color: $n500;
		position: relative;
		overflow: visible;
		&:before {
			content: "";
			height: 100%;
			width: 2px;
			background-color: $primary;
			position: absolute;
			left: -5px;
			top: 0;
			opacity: 0;
			transition: 0.3s ease;
		}
		&.MuiListItem-root.Mui-selected {
			background-color: $primary;
			color: $n000;
		}
		&:focus {
			&:before {
				opacity: 1;
			}
		}
		&:hover {
			color: $n500;
			background-color: $primaryLighter;
		}
	}

	.company-image {
		height: 100px;
		width: 100px;
		border-radius: 50%;
		min-height: unset;
		margin: 0 auto;
		position: relative;
		background-size: cover;
		background-position: center;
		box-shadow: 0px 0px 5px $greySuperLight;
	}
}

.form__image--input {
	position: relative;
	height: 72px;
	width: 72px;
	border-radius: $borderRadiusSmall;
	min-height: unset;
	//margin: -60px auto 0;

	//margin: auto;
	border: none;
	background-color: transparent;
	position: relative;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	transition: 0.2s ease;

	.image--added {
		height: 72px;
		width: 72px;
		background-size: 85% auto;
		border-radius: 50%;
		background-repeat: no-repeat;
		background-position: center;
		background-color: $n000;
	}
	.image__edit {
		height: 28px;
		width: 28px;
		border-radius: $borderRadiusSmall;
		background: $primary;
		padding: 6px;
		position: absolute;
		bottom: 0px;
		right: 0px;
		svg * {
			fill: $n000;
			height: 16px;
		}
	}

	&--profile {
		background-size: cover;
		.image--added {
			background-size: cover;
		}
	}

	svg {
		height: 100%;
		width: auto;
		position: relative;
		z-index: 1;
	}

	&:hover {
		.target-fill {
			fill: $primaryDark;
		}
		.target-stroke {
			stroke: $primaryDark;
		}
	}
}

.standardized-form--edit {
	.image--added {
		border: 1px solid $primary;
	}
}

.dropdown-list {
	padding: 8px 4px;
}

.MuiList-padding.dropdown-list {
	@extend .dropdown-list;
}

.dropdown-subheader {
	color: $n500 !important;
	padding: 4px 0px 4px 16px;
	margin-left: -16px;
	margin-right: -16px;
	&--border {
		border-top: 1px solid $n150;
	}
}

.dropdown-item {
	padding: 12px !important;
	border-radius: $borderRadiusSmall;
	cursor: pointer;
	font-size: 0.875rem;
	transition: 0.3s ease;
	color: $n500;
	position: relative;
	font-family: $interRegular;
	overflow: initial;
	padding-left: 12px !important;

	&:before {
		content: "";
		height: 100%;
		width: 2px;
		background-color: $primary;
		position: absolute;
		left: -5px;
		top: 0;
		opacity: 0;
		transition: 0.3s ease;
	}

	&:focus {
		&:before {
			opacity: 1;
		}
	}
	&:hover {
		color: $n500 !important;
		//background-color: $primaryLighter !important;

		background-color: $blueLight !important;
	}

	&[aria-selected="true"],
	&--selected {
		//background-color: $primary !important;
		background-color: $primaryLighter !important;
		//color: $n000 !important;
		color: $n500 !important;
		&:hover {
			color: $n500 !important;
			background-color: $blueLight !important;
			//color: $n000 !important;
			//background-color: $primaryLight !important;
		}
	}
	&--sub {
		padding-left: 44px !important;
		margin-left: -20px;
	}
	&--selected {
		&--clear {
			background-color: $n150;
		}
	}

	&--trashed {
		background-color: $n150 !important;
	}

	&--clear {
		&:hover {
			background-color: $n100 !important;
		}
	}

	&--checkbox {
		&.dropdown-item--selected {
			// has to have !important because it's not available on load
			background-color: $primaryLighter !important;
			color: $n500 !important;
		}
		&:hover {
			background-color: $blueLight;
			//background-color: $primaryLighter !important;
		}
	}

	&--disabled {
		background-color: $n150 !important;
	}

	.checkbox {
		display: inline-flex;
		height: 16px;
		width: 16px;
		color: $n000;
		box-sizing: border-box;
		padding: 3px;
		border-radius: $borderRadiusSmall;
		margin-right: 8px;
		background-size: 11px auto;
		background-repeat: no-repeat;
		background-position: center;
		transition: 0.2s all;
		box-shadow: inset 0 0 0 1px $n200;
		&--small {
			height: 12px;
			width: 12px;
		}
		.icon,
		svg {
			display: none;
		}
		&--selected {
			background-color: $primary;
			box-shadow: inset 0 0 0 2px $primary;
			.icon {
				display: flex;
				align-items: center;
				height: 100%;
			}
			svg {
				max-height: 100%;
				max-width: 100%;
				display: flex;
				* {
					stroke: $white;
				}
			}
		}
	}
}

.MuiMenuItem-root.dropdown-item,
.MuiAutocomplete-option.dropdown-item {
	@extend .dropdown-item;
}

.checkbox-selector {
	color: $n500;
	font-family: $interRegular;
	outline: none;
	border: none;
	background-color: transparent;
	align-items: center;
	display: flex;

	.checkbox {
		display: inline-flex;
		height: 16px;
		width: 16px;
		border: 1px solid $n200;
		box-sizing: border-box;
		border-radius: $borderRadiusSmall;
		margin-right: 8px;
		background-size: 11px auto;
		background-repeat: no-repeat;
		background-position: center;
		transition: 0.2s all;
		&--selected {
			background-color: $primary;
			border-color: $primary;
			background-image: url(../images/checkmark-white-icon.svg);
		}
	}
	&--selected {
		font-family: $interMedium;
		.checkbox {
			background-color: $primary;
			border-color: $primary;
			background-image: url(../images/checkmark-white-icon.svg);
		}
	}
	&:hover {
		.checkbox {
			border-color: $primaryLight;
		}
	}
	&:focus {
	}
	&:active {
	}
}

.file-preview {
	height: 48px;
	width: 48px;
	background-size: 90% auto;
	background-repeat: no-repeat;
	background-position: center;
	position: relative;
	align-items: center;
	justify-content: center;
	display: flex;
	background: url(../../assets/images/icons/misc/file-icon.svg) no-repeat center;
	&__remove {
		position: absolute;
		top: -$s;
		left: calc(100% - $m);
		z-index: 1;
		&:hover ~ .tooltip__body .file-preview {
			border-color: $n300;
		}
	}
	&--uploading::after {
		content: "";
		position: absolute;
		background-color: transparentize($n000, 0.3);
		height: 100%;
		width: 100%;
	}
}

.dragging-area {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 1;
	background-color: transparentize($n000, 0.3);
	pointer-events: none;
}

.file-input {
	position: relative;
	cursor: pointer;
	transition: 0.2s ease;

	.upload-icon {
		margin-right: $regular;
		svg * {
			transition: 0.2s ease;
			stroke: $primary;
		}
	}

	&--dropzone {
		width: 100%;
		height: 100%;
		background-color: $blueLight;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px dashed $n300;
		box-sizing: border-box;
		border-radius: 6px;

		svg * {
			transition: 0.2s ease;
		}

		&:hover {
			background-color: $n150;
			border-color: $n200;
			color: $n500;
			.upload-icon svg * {
				stroke: $n500;
			}
		}
	}
	&--disabled {
		cursor: not-allowed;
	}

	&--dragging {
		background-color: $successLight;
		&:hover {
			background-color: $successLight;
		}
	}

	&--small {
		min-height: unset;
		padding: $regular;
	}
}

.autocomplete__input {
	padding: 0 !important;
}
