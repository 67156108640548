@import "../../assets/styles/variables";

.tooltip {
	position: relative;
	display: inline-block;

	&:hover {
		.tooltiptext {
			visibility: visible;
			opacity: 1;
		}
	}
	.tooltiptext {
		visibility: hidden;
		position: absolute;
		width: auto;
		background-color: $n500;
		color: $n000;
		padding: 8px 24px;
		border-radius: 6px;
		z-index: 5;
		opacity: 0;
		transition: 0.3s;
		&--icon {
			width: 100px !important;
			padding: 8px 0px !important;
			text-align: center !important;
		}
	}

	&--top {
		bottom: 125%;
		left: calc(50% / 2 - 48px);

		&::after {
			content: "";
			position: absolute;
			top: 100%;
			left: 50%;
			margin-left: -5px;
			border-width: 5px;
			border-style: solid;
			border-color: $n500 transparent transparent transparent;
		}
	}

	&--icon-type {
		bottom: 165%;
		left: calc(50% / 2 - 58px);

		&::after {
			content: "";
			position: absolute;
			top: 100%;
			left: 50%;
			margin-left: -5px;
			border-width: 5px;
			border-style: solid;
			border-color: $n500 transparent transparent transparent;
		}
	}

	&--top-start {
		bottom: 125%;

		&::after {
			content: "";
			position: absolute;
			top: 100%;
			left: 50%;
			margin-left: -5px;
			border-width: 5px;
			border-style: solid;
			border-color: $n500 transparent transparent transparent;
		}
	}
	&--right {
		top: calc(50% - 20px);
		left: 125%;

		&::after {
			content: "";
			position: absolute;
			top: 50%;
			right: 100%;
			margin-top: -5px;
			border-width: 5px;
			border-style: solid;
			border-color: transparent $n500 transparent transparent;
		}
	}
	&--bottom {
		top: 135%;
		left: 50%;
		margin-left: -60px;
		&::after {
			content: "";
			position: absolute;
			bottom: 100%;
			left: 50%;
			margin-left: -5px;
			border-width: 5px;
			border-style: solid;
			border-color: transparent transparent $n500 transparent;
		}
	}
	&--left {
		top: calc(50% - 20px);
		bottom: auto;
		right: 128%;
		&::after {
			content: "";
			position: absolute;
			top: 50%;
			left: 100%;
			margin-top: -5px;
			border-width: 5px;
			border-style: solid;
			border-color: transparent transparent transparent $n500;
		}
	}
}
