@import "../../../assets/styles/variables";

.page-header-stepper {
	//	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.071);
	//background-color: $n000;
	position: fixed;
}
.create-project-stepper {
	z-index: 2;
	.MuiPaper-root {
		background-color: transparent;
	}
	width: calc(100% - #{$sidebarWidth});

	.mobile-stepper {
		align-items: center;
		justify-content: center;
		display: flex;
		//background-color: $n000;
	}

	.stepper {
		//	justify-content: center;
		padding: 0;
	}

	.connector {
		max-width: 40px;
		.line {
			border-top-style: dotted;
			border-top-width: 2px;
		}
		&--completed,
		&--active {
			.line {
				border-top-style: solid;
			}
		}
		&--completed .line {
			border-color: $n300;
		}
		&--active .line {
			border-color: $primary;
		}
	}

	&--helper {
		min-height: 62px;
		height: 62px;
		width: 100%;
		@include mq("desktop") {
			min-height: 40px;
			height: 40px;
		}
	}

	.stepper {
		&__label {
			height: 28px;
			width: 28px;
			border-radius: 50%;
			box-shadow: $boxShadow2;
			border: 1px solid $n150;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: $n000;
			h5 {
				line-height: 1;
			}

			&--active {
				background-color: $primary;
				border-color: $primary;
				color: $primary;
			}

			&--completed {
				border-color: $n300;
				background-color: $n300;
				color: $n000;
			}
		}
	}
}
