/**Helper classes */
.opacity-0-3 {
	opacity: 0.3;
}

.opacity-0 {
	opacity: 0;
}

.opacity-1 {
	opacity: 1;
}

.opacity--50 {
	opacity: 0.5;
}
.error-message {
	color: $red;
	font-family: $interItalic;
	@extend .body-font--xs;
}

.return-link {
	position: absolute;
	z-index: 1;
	top: $regular;
	left: $regular;
	color: $n300;

	@include mq("tablet") {
		top: 30px;
		left: 30px;
	}
	&--order {
		top: 14px;

		svg {
			height: 16px;
		}
	}

	&:hover {
		text-decoration: underline;
		cursor: pointer;
	}
	svg * {
		fill: $n300;
	}
	h6 {
		color: $n300;
		line-height: 10px; // height of return icon
	}
}

.messages-counter {
	height: 25px;
	width: 25px;
	border-radius: $borderRadiusLarge;
	display: inline-block;
	text-align: center;
	color: $n000;
}

.pt-2 {
	padding-top: 2px;
}

.pr-unset {
	padding-right: unset !important;
}

.m-auto {
	margin: auto;
}

.border-radius-10 {
	border-radius: 10px;
}

.rounded {
	border-radius: $borderRadiusRegular;
}

.rounded--small {
	border-radius: $borderRadiusSmall;
}

.rounded--large {
	border-radius: $borderRadiusLarge;
}

.border-bottom-unset {
	border-bottom: unset !important;
}

.cursor-pointer {
	cursor: pointer;
}
.cursor-text {
	cursor: text;
}

.cursor-grab {
	cursor: grab;
	&:active {
		cursor: grabbing;
	}
}

.cursor-wait {
	cursor: wait;
}

.disabled {
	pointer-events: none;
	cursor: default;
}

.pointer-events--none {
	pointer-events: none;
}

.td-underline {
	text-decoration: underline;

	&:hover {
		cursor: pointer;
	}
}

.cursor-pointer :hover {
	cursor: pointer;
}

.cursor {
	&--pointer {
		cursor: pointer;
	}
	&--not-allowed {
		cursor: not-allowed;

		* {
			cursor: not-allowed;
			pointer-events: none;
		}
	}
	&--wait {
		cursor: wait;
		&.icon-btn,
		&.menu-list-item {
			cursor: wait;
		}
	}
}

.line-break-anywhere {
	line-break: anywhere;
}

.white-space {
	&--nowrap {
		white-space: nowrap;
	}
	&--pre {
		white-space: pre;
	}
}
.text-overflow-ellipsis {
	white-space: nowrap;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
}

.color-grey-superdark {
	color: $greySuperDark;
}

.color-grey-super-light {
	color: $greySuperLight;
}

.color-dark-gray {
	color: #404040;
}

.color-pinkswan {
	color: $pinkSwan;
}

.color--primary {
	color: $primary;
}

.color-pink-swan-lightest {
	color: $pinkSwanLightest !important;
}

.color--red {
	color: $redDark;
}

.color--success-dark {
	color: $successDark;
}

.background-white-smoke {
	background-color: $whiteSmoke;
}

.background-orange {
	background-color: $primary !important;
}

.background-grey-super-light {
	background-color: $greySuperLight !important;
}

.color-orange {
	color: $primary;
}

// divider

.h-divider {
	height: 1px;
	background-color: #e6e6e6;
	width: 100%;
}

// .upload-file-progress {
// 	stroke-linecap: round;
// 	height: 4px;
// 	background-color: green;
// }

// POSITIONING

.pos-abs {
	position: absolute;
}

.pos-rel {
	position: relative;
}

.pos-fix {
	position: fixed;
}

.overflow-y-scroll {
	overflow-y: scroll;
}
.word-break-all {
	word-break: break-all;
}
.overflow-hidden {
	overflow: hidden;
}

.overflow-y-auto {
	overflow-y: auto;
}

.overflow-x-hidden {
	overflow-x: hidden;
}
.overflow-y-hidden {
	overflow-y: hidden;
}
.overflow-x-auto {
	overflow-x: auto;
}

.float-left {
	float: left;
}

.float-right {
	float: right;
}

$widthValues: (
	"12-5p": 12.5px,
	"24p": 24px,
	"40p": 40px,
	"50p": 50px,
	"84p": 84px,
	"100p": 100px,
	"120p": 120px,
	"140p": 140px,
	"180p": 180px,
);

$minWidthValues: (
	"120p": 120px,
	"200p": 200px,
	"80": 80%,
	"100": 100%,
);

$minWidthPixelValues: (
	"80": 80px,
	"90": 90px,
	"135": 135px,
);

$maxWidthValues: (
	"30p": 30px,
	"70p": 70px,
	"100p": 100px,
	"120p": 120px,
	"150p": 150px,
	"160p": 160px,
	"170p": 170px,
	"195p": 195px,
	"200p": 200px,
	"340p": 340px,
	"480p": 480px,
	"550p": 550px,
	"1200p": 1200px,
	"80": 80%,
	"55": 55%,
	"90": 90%,
	"100": 100%,
);

@each $label, $value in $widthValues {
	.w-#{$label} {
		width: $value;
	}
}

@each $label, $value in $minWidthValues {
	.min-w-#{$label} {
		min-width: $value;
	}
}

@each $label, $value in $maxWidthValues {
	.max-w-#{$label} {
		max-width: $value;
	}
}

$heightValues: (
	"90": 90%,
	"95": 95%,
	"100": 100%,
	"20p": 20px,
	"24p": 24px,
	"33p": 33px,
	"40p": 40px,
	"44p": 44px,
	"46p": 46px,
	"48p": 48px,
	"50p": 50px,
	"52p": 52px,
	"56p": 56px,
	"60p": 60px,
	"64p": 64px,
	"73p": 73px,
	"88p": 88px,
	"96p": 96px,
	"100p": 100px,
	"120p": 120px,
	"271p": 271px,
	"280p": 280px,
	"320p": 320px,
	"350p": 350px,
	"360p": 360px,
	"400p": 400px,
	"500p": 500px,
	"570p": 570px,
	"600p": 600px,
	"100vh": 100vh,
);

@each $label, $value in $heightValues {
	.h-#{$label} {
		height: $value;
	}
}

$minHeightValues: (
	"unset": unset,
	"100vh": 100vh,
	"100p": 100px,
	"50p": 50px,
	"88p": 88px,
);

@each $label, $value in $minHeightValues {
	.mh-#{$label} {
		min-height: $value;
	}
}

$maxHeightValues: (
	"48p": 48px,
	"88p": 88px,
	"100p": 100px,
	"190p": 190px,
	"390p": 390px,
	"450p": 450px,
	"470p": 470px,
	"500p": 500px,
	"520p": 520px,
	"570p": 570px,
	"621p": 621px,
	"650p": 650px,
);

@each $label, $value in $maxHeightValues {
	.max-h-#{$label} {
		max-height: $value;
	}
}

.d-none {
	display: none;
}

.d-none-imp {
	display: none !important;
}

@include mq("tablet") {
	.d-tablet-none {
		display: none;
	}

	.d-tablet-block {
		display: block;
	}
}

@include mq("desktop") {
	.d-desktop-none {
		display: none;
	}

	.d-desktop-block {
		display: block;
	}
}

///////

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	&--light {
		background-color: rgba(255, 255, 255, 0.3);
	}
}

.bg-cover {
	background-size: cover;
}
.text-center {
	text-align: center;
}

.bg-center {
	background-position: center;
}

.tabs {
	min-height: unset;

	&__wrapper {
		background-color: transparent !important;
		box-shadow: none !important;
	}

	&__indicator {
		display: none;
	}

	.flex-container {
		display: inline-flex;
	}

	.tab {
		//	font-size: 1rem; // to overwrite material native styles
		line-height: 1.5; // to overwrite material native styles
		padding: 0 $m 11px;
		min-width: unset;
		min-height: unset;
		text-transform: none;
		font-family: $interMedium;
		color: $n300;

		$tabIcons:
			"chat" "chat-icon.svg" $n300 $n500,
			"files" "folder-icon.svg" $n300 $n500,
			"quotes" "quotes-icon.svg" $n300 $n500,
			"invoices" "invoice-icon.svg" $n300 $n500,
			"buildings" "projects-icon.svg" $n300 $n500;

		&__icon {
			align-items: center;

			@each $modifier, $path, $color, $color2 in $tabIcons {
				&--#{$modifier}:before {
					-webkit-mask: url(../../assets/images/nav-icons/#{$path})
						no-repeat
						50%
						50%;
					mask: url(../../assets/images/nav-icons/#{$path}) no-repeat 50% 50%;
					background-color: $color;
				}

				@if $color2 {
					&--#{$modifier}--active:before {
						background-color: $color2;
					}
				}
			}
			&:before {
				display: none;
				content: "";
				-webkit-mask-size: contain;
				mask-size: contain;
				height: 20px;
				width: 20px;
				display: block;
				margin-right: 10px;
			}
		}

		&--selected {
			font-family: $interSemibold;
			color: $n500;

			&::after {
				content: "";
				position: absolute;
				z-index: 2;
				bottom: 0px;
				height: 2px;
				width: 100%;
				background-color: $primary !important;
				display: block;
				width: calc(100% - 24px);
				left: 12px;
			}

			.tab__icon {
				@each $modifier, $path, $color, $color2 in $tabIcons {
					@if $color2 {
						&--#{$modifier}:before {
							background-color: $color2;
						}
					}
				}
			}

			.icon {
				.target-fill {
					fill: $primary;
				}
				.target-stroke {
					stroke: $primary;
				}
			}
		}

		&:first-child {
			padding-left: 0px;
			&.tab--selected::after {
				width: calc(100% - 12px);
				left: 0;
			}
		}
		&:last-child {
			padding-right: 0px;
			&.tab--selected::after {
				width: calc(100% - 12px);
				left: 12px;
			}
		}

		&--btn.tab {
			@extend .btn;
			@extend .btn--tertiary;
			padding: 7px 24px;
			border-color: transparent;
			&.tab--selected {
				@extend .btn--secondary;
				font-family: $interMedium;
			}
			&:after {
				display: none;
			}
		}

		&--py-10 {
			padding-top: 10px;
			padding-bottom: 10px;
		}
	}
	.tab-part {
		line-height: 1.5; // to overwrite material native styles
		padding: 8px 12px;
		min-width: unset;
		min-height: unset;
		text-transform: none;
		font-family: $interMedium;
		color: $n300;

		&--selected {
			font-family: $interSemibold;
			color: $n500;
			background-color: $n100;
			border-radius: 3px;

			&.circle {
				width: 8px;
				height: 8px;
				background-color: $primary;
				border-radius: 50%;
				margin-right: 8px;
			}
		}
	}
}

.MuiTabs-root.tabs {
	@extend .tabs;
}

.dropdown__wrapper {
	.title-button {
		min-width: unset;
	}
}

.visibility-hidden {
	visibility: hidden;
	opacity: 0;
}

.menu-list-item {
	&.disabled {
		@extend .cursor--not-allowed;
	}
}

.filter-overlay__wrapper {
	.filter-overlay {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		filter: blur($xs);
		backdrop-filter: blur(1px);
		opacity: 0;
		transition: 0.2s ease;
		z-index: 1;
		background: transparentize($n000, 0.5);
	}
	&:hover .filter-overlay {
		opacity: 1;
	}
}

.DraftEditor-root {
	min-height: 50px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
	display: none;
}

.page-header {
	height: 96px;
}

.job {
	&--configuration {
		height: calc(100vh - 350px);
	}
	&--description {
		height: calc(100vh - 399px);
	}
}

.page-content {
	height: calc(100vh - 240px);

	&--403 {
		height: calc(100vh - 403px);
	}
	&--lod-specification {
		height: calc(100vh - 353px);
	}

	&--dashboard {
		height: calc(100vh - 310px);
	}
	&--large {
		height: calc(100vh - 120px);
	}
	&--215 {
		height: calc(100vh - 215px);
	}
	&--large-body {
		height: calc(100vh - 220px);
	}
	&--medium {
		height: calc(100vh - 304px);
	}
	&--328 {
		height: calc(100vh - 328px);
	}
	&--389 {
		height: calc(100vh - 389px);
	}
	&--381 {
		height: calc(100vh - 381px);
	}
	&--340 {
		height: calc(100vh - 340px);
	}
	&--329 {
		height: calc(100vh - 329px);
	}
	&--517 {
		height: calc(100vh - 517px);
	}
	&--429 {
		height: calc(100vh - 429px);
	}
	&--477 {
		height: calc(100vh - 477px);
	}
	&--514-max {
		max-height: calc(100vh - 514px);
	}
	&--464-max {
		max-height: calc(100vh - 464px);
	}
	&--300-max {
		max-height: calc(100vh - 300px);
	}
	&--345-max {
		max-height: calc(100vh - 345px);
	}
	&--small {
		height: calc(100vh - 420px);
	}
	&--administration {
		height: calc(100vh - 289px);
		&-lod-content {
			height: calc(100vh - 433px);
			&-edit {
				height: calc(100vh - 521px);
			}
		}
	}
	&--334 {
		height: calc(100vh - 334px);
	}
	&--422 {
		height: calc(100vh - 422px);
	}
	&--338 {
		height: calc(100vh - 338px);
	}

	&--372 {
		height: calc(100vh - 372px);
	}

	&--administration-edit {
		height: calc(100vh - 377px);
	}
	&--xs {
		height: calc(100vh - 485px) !important;
	}

	&-table {
		height: calc(100vh - 428px);

		&--477 {
			height: calc(100vh - 477px);
		}
		&--522 {
			height: calc(100vh - 522px);
		}
		&--502 {
			height: calc(100vh - 502.62px);
		}

		&--526 {
			height: calc(100vh - 526px);
		}
		&--550 {
			height: calc(100vh - 550px);
		}
		&--medium {
			height: calc(100vh - 450px);
		}

		&--small {
			height: calc(100vh - 480px);
		}
		&--xs {
			height: calc(100vh - 499px);
		}
	}
}

.unread-comments-counter {
	position: absolute !important;
	margin-top: -4px;
	margin-right: -10px;
}

.DraftEditor-editorContainer {
	h1,
	h2,
	h3,
	h4 {
		&:not(:first-child) {
			margin-top: 20px;
		}

		margin-bottom: 20px;
	}
}
.public-DraftStyleDefault-block {
	margin-bottom: 0.5px;
}

/*Borders*/

$borderWidth: (
	"1": 1px,
	"2": 2px,
	"3": 3px,
	"4": 4px,
	"6": 6px,
	"8": 8px,
);

@each $key, $value in $borderWidth {
	.border-#{$key} {
		border: $value solid;
	}
	.border-r-#{$key} {
		border-right: $value solid;
	}
	.border-l-#{$key} {
		border-left: $value solid;
	}
	.border-t-#{$key} {
		border-top: $value solid;
	}
	.border-b-#{$key} {
		border-bottom: $value solid;
	}
}
$borderColor: (
	"n150": $n150,
	"n200": $n200,
	"primary": $primary,
	"primary-light": $primaryLight,
	"transparent": transparent,
	"p300": $p300,
);

@each $key, $value in $borderColor {
	.border-#{$key} {
		border-color: $value;
	}
}

$borderRadius: (
	"small": $borderRadiusSmall,
	"regular": $borderRadiusRegular,
);
@each $key, $value in $borderRadius {
	.border-radius-#{$key} {
		border-radius: $value;
	}
}

/*----------------------------------------------------------*/

.bimify-circle {
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: $n100;

	&-40 {
		width: 40px;
		height: 40px;
		min-width: 40px;
		min-height: 40px;
	}
	&-32 {
		width: 32px;
		height: 32px;
		min-width: 32px;
		min-height: 32px;
	}
}

.autocomplete__popper {
	width: 100% !important;
	min-width: fit-content !important;
	max-height: fit-content !important;
	height: auto !important;
}

.accordion-default {
	&.MuiAccordion-root {
		background-color: transparent;
		&.bg-n100 {
			background-color: $n100;
		}
		&::before {
			background-color: $n150;
		}
		&.Mui-expanded {
			&::before {
				background-color: $n150;
				opacity: unset;
			}
		}
	}

	.MuiAccordionSummary-root {
		padding-left: 0px;
		padding-right: 0px;
	}
	.MuiAccordionDetails-root {
		padding: 0px;
	}
	.DraftEditor-root {
		min-height: 0px;
	}

	&--edit {
		.MuiAccordionSummary-root {
			height: 52px;
			&.Mui-expanded {
				height: 52px;
				min-height: unset;
			}
		}
		.MuiAccordionSummary-expandIconWrapper {
			padding: 12px;
			margin-right: 32px;
		}
	}
}

.border-last-child {
	&:last-child {
		border: none;
	}
}

.package-image-resizer {
	object-fit: cover;
}

.of-contain {
	object-fit: contain;
}

.of-cover {
	object-fit: cover;
}

.z-index-2 {
	z-index: 2;
}

.lod-specification-editor {
	.public-DraftStyleDefault-ol,
	.public-DraftStyleDefault-ul {
		margin: 0px !important;
		padding: 0px;
	}
}

.image-control-wrapper--resizing {
	touch-action: none;
}

#webpack-dev-server-client-overlay-div {
	display: none !important;
}

.MuiAutocomplete-popper {
	transform: translate(0px, 53px) !important;
	transition: none !important;
}
.autocomplete-popper {
	.MuiAutocomplete-popper {
		left: 3px !important;
	}
}

iframe#webpack-dev-server-client-overlay {
	display: none;
}

.transition-classes {
	transition: width 0.3s ease-in-out;
}
