@import "../variables";

.icon {
	svg {
		max-height: 100%;
		width: auto;
	}

	&--xs {
		svg {
			width: 12px;
			height: auto;
		}
	}
	&--24 {
		svg {
			width: 24px;
			min-height: 24px;
			height: auto;
		}
	}
	&--32 {
		svg {
			width: 24px;
			min-height: 24px;
			height: auto;
		}
	}
	&--30 {
		svg {
			width: 30px;
			height: auto;
		}
	}

	&--xl {
		svg {
			width: 64px;
			height: 64px;
		}
	}

	&--target-fill {
		&.fill--n000 {
			svg * {
				fill: $n000;
			}
		}
		&.fill--n300 {
			svg * {
				fill: $n300;
			}
		}
		&.fill--n350 {
			svg * {
				fill: $n350;
			}
		}
		&.fill--n400 {
			svg * {
				fill: $n400;
			}
		}
		&.fill--n500 {
			svg * {
				fill: $n500;
			}
		}
		&.fill--primary {
			svg * {
				fill: $primary;
			}
		}
		&.fill--red {
			svg * {
				fill: $red;
			}
		}

		&.fill--n200 {
			svg * {
				fill: $n200;
			}
		}
		&.fill--blue {
			svg * {
				fill: $blue;
			}
		}
		&.fill--green {
			svg * {
				fill: $green;
			}
		}
	}

	&--n300 {
		.target--fill {
			svg * {
				fill: $n300;
			}
		}
		.target--stroke svg * {
			stroke: $n300;
		}
	}

	&--n500 {
		.target-fill {
			fill: $n500;
		}
		.target-stroke {
			stroke: $n500;
		}
	}

	&--primary {
		&.target--fill svg * {
			fill: $primary;
		}

		&.target--stroke svg * {
			stroke: $primary;
		}
	}
}

.icon__status {
	svg * {
		fill: $n300;
	}

	&--uncategorised,
	&--archived {
		svg * {
			fill: $n400;
		}
	}
	&--customisation,
	&--qa,
	&--offered {
		svg * {
			fill: $blue;
		}
	}

	&--ongoing,
	&--normalisation,
	&--done,
	&--pending,
	&--requested {
		svg * {
			fill: $primary;
		}
	}
	&--reviewed,
	&--completed,
	&--accepted,
	&--delivered,
	&--categorised,
	&--resolved,
	&--active {
		svg * {
			fill: $green;
		}
	}

	&--incomplete,
	&--declined {
		svg * {
			fill: $red;
		}
	}
}

.icon-cell {
	display: flex;
	align-items: center;
	justify-content: center;
	background: $n100;
	width: 36px;
	height: 36px;
	border: 2px solid $n000;
	padding: 2px;
	box-sizing: border-box;
	border-radius: 50%;
	box-shadow: 0px 6px 8px -4px rgba(127, 144, 159, 0.16);
	&--40 {
		width: 40px;
		height: 40px;
	}

	&--20 {
		width: 20px;
		height: 20px;
	}

	&--small {
		width: 24px;
		height: 24px;
	}
	&--32 {
		width: 32px;
		height: 32px;
	}
	&--36 {
		width: 36px;
		height: 36px;
	}
	&--48 {
		width: 48px;
		height: 48px;
	}
	// &--medium {
	// 	width: 40px;
	// 	height: 40px;
	// }

	&--large {
		// width: 72px;
		// height: 72px;
		width: 68px;
		height: 68px;
	}
}
.icon-wrapper {
	display: flex;
	justify-content: center;
	max-width: 40px;
	min-width: 40px;
	height: 40px;

	&--m {
		max-width: 12px;
		min-width: 12px;
		height: 12px;
	}

	&--16 {
		max-width: 16px;
		min-width: 16px;
		height: 16px;
	}

	&--small {
		max-width: 20px;
		min-width: 20px;
		height: 20px;
	}
	&--22 {
		max-width: 22px;
		min-width: 22px;
		height: 22px;
	}
	&--24 {
		max-width: 24px;
		min-width: 24px;
		height: 24px;
	}
	&--32 {
		max-width: 32px;
		min-width: 32px;
		height: 32px;
	}
	&--36 {
		max-width: 36px;
		min-width: 36px;
		height: 36px;
	}
	&--sm {
		max-width: 28px;
		min-width: 28px;
		height: 28px;
	}
	&--medium {
		max-width: 40px;
		min-width: 40px;
		height: 40px;
	}
	&--48 {
		max-width: 48px;
		min-width: 48px;
		height: 48px;
	}

	&--large {
		max-width: 54px;
		min-width: 54px;
		height: 54px;
	}
	&--72 {
		max-width: 72px;
		min-width: 72px;
		height: 72px;
	}
	&--x-large {
		max-width: 80px;
		min-width: 80px;
		height: 80px;
	}
	&--round {
		border-radius: 50%;
		align-items: center;
		.icon {
			display: flex;
		}
	}
	.icon {
		width: 100%;
		height: 100%;
		svg {
			width: 100%;
			height: auto;
			align-self: flex-start;
		}
	}
}

.loading-wrapper {
	&--absolute {
		position: absolute;
		height: 100%;
		width: 100%;
		display: flex;
		background-color: transparentize($n000, 0.3);
		top: 0;
		left: 0;
		align-items: center;
		justify-content: center;
		z-index: 1;
		flex-direction: column;
	}
}

.loading-icon {
	display: inline-block;
	height: 14px;
	width: 14px;
	border-radius: 50%;
	border: 1px solid $n500;
	border-top: 1px solid $n000;
	animation: spin 2s linear infinite;

	&.wh-16p {
		width: 16px;
		height: 16px;
	}

	&.wh-24p {
		width: 24px;
		height: 23px;
	}
	&.wh-50p {
		width: 50px;
		height: 50px;
	}
}
