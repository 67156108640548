//CSS file related to users management
.dtable .dtable__body {
	.dtable__body-row {
		.dtable__body-item {
			.email-cell {
				position: relative;
				.btn {
					position: absolute;
					bottom: -1.3rem;
					left: 0;
					margin: auto;
					opacity: 1;
					visibility: visible;
				}
			}
		}
		&:hover {
			.dtable__body-item {
				.email-cell {
					.btn {
						opacity: 1;
						visibility: visible;
						transform: translateY(0);
					}
				}
			}
		}
	}
}
