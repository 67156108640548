@import "../variables";

$neutrals: (
	"n000": $n000,
	"n050": $n050,
	"n100": $n100,
	"n150": $n150,
	"n200": $n200,
	"n300": $n300,
	"n301": $n301,
	"n350": $n350,
	"n400": $n400,
	"n500": $n500,
	"p300": $p300,
	"g300": $g300,
	"y300": $y300,
	"primary": $primary,
	"red": $red,
	"row--hover": $rowHover,
	"primary--dark": $primaryDark,
	"primary--light": $primaryLight,
	"primary--lighter": $primaryLighter,
	"primary--selected": $primarySelected,
	"success": $success,
	"success--light": $successLight,
	"red--light": $redLight,
	"blue--light": $blueLight,
	"blue--dark": $blueDark,
	"error--light": $errorLight,
	"info": $info,
	"inactive": $inactive,
	"transparent": transparent,
	"white-smoke": $whiteSmoke,
);

@each $mod, $color in $neutrals {
	.bg-#{$mod} {
		background-color: $color;
	}

	.color-#{$mod} {
		color: $color;
	}
}
