@import "../../../assets/styles/variables";

/* Wrapping */
.tooltip-wrapper {
	display: inline-block;
	position: relative;
	width: fit-content;
	max-width: 100%;
}

/* Absolute positioning */
.tooltip-tip {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	background-color: $n500;
	color: $n000;
	padding: 8px 24px;
	border-radius: 6px;
	z-index: 5;
	transition: 0.3s;
	white-space: nowrap;

	&.top {
		bottom: 125%;

		::before {
			top: 100%;
			border-top-color: $n500;
		}
	}

	&.right {
		left: calc(100% + $tooltipMargin);
		top: 50%;
		transform: translateX(0) translateY(-50%);

		::before {
			left: calc($tooltipArrowSize * -1);
			top: 50%;
			transform: translateX(0) translateY(-50%);
			border-right-color: $n500;
		}
	}

	&.bottom {
		top: 135%;
		::before {
			bottom: 100%;
			border-bottom-color: $n500;
		}
	}

	&.left {
		left: auto;
		right: calc(100% + $tooltipMargin);
		top: 50%;
		transform: translateX(0) translateY(-50%);

		::before {
			left: auto;
			right: calc($tooltipArrowSize * -1.9);
			top: 50%;
			transform: translateX(0) translateY(-50%);
			border-left-color: $n500;
		}
	}

	::before {
		content: " ";
		left: 50%;
		border: solid transparent;
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-width: $tooltipArrowSize;
		margin-left: calc($tooltipArrowSize * -1);
	}
}
