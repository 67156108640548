@import "../../assets/styles/variables";

.accordion {
	border-radius: $borderRadiusRegular !important;
	box-shadow: none !important;
	&--shadow {
		box-shadow: 0px 6px 8px -4px transparentize($n300, 0.84) !important;
	}
	&--none {
		border-radius: 0 !important;
		box-shadow: none !important;
	}
	&:before,
	&:after {
		content: none !important;
	}
}
